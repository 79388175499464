import { Component, OnInit, Input } from '@angular/core';
import { MessageService } from '../../../app/services/message.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  @Input() deviceXs: boolean;

  isDarkChecked: boolean = false;

  constructor(private messageService:MessageService) { }

  ngOnInit(): void {
    //this.deviceXs;
  }

  changeTheme(e) {
    let themeName = '';
    if (e.target.checked === true) {
      themeName = 'darkstyles';
    } else {
      themeName = 'styles';
    }
    this.messageService.sendMessage(e.target.checked);
    const theme = document.getElementById('themeAsset') as HTMLElement;
    theme.setAttribute('href', `./assets/css/${themeName}.css`);
  }

}
