import { Component, OnInit } from '@angular/core';
import { ApibundleService } from '../../services/apibundle.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { BroadcastService, MsalService } from '@azure/msal-angular';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';
import { LicenseDetailResponse } from '../../interfaces/licenseDetailResponse.model';

@Component({
  selector: 'app-license-view',
  templateUrl: './license-view.component.html',
  styleUrls: ['./license-view.component.css']
})
export class LicenseViewComponent implements OnInit {
  reqFromUrl: any;
  licenseDetail = new LicenseDetailResponse();
  selectedApps: any;
  err: any;
  spinner: boolean = false;
  spinnerName = "licenseView";
  constructor(
    private apibundleService: ApibundleService,
    private router: Router,
    private route: ActivatedRoute,
    private broadcastService: BroadcastService,
    private authService: MsalService,
    public location: Location) { 
    this.reqFromUrl = this.route.snapshot.paramMap.get('reqId');
  }

  ngOnInit(): void {
    this.fetchRequest(this.reqFromUrl);
  }

  /*--------  FUNCTION TO GET LICENSE REQUEST USING REQUEST ID -------- */
  async fetchRequest(reqId) {
    this.apibundleService.showSpinner('licenseView'); 
    this.spinner = true; 
    this.apibundleService.getLicenseById(reqId).subscribe(
      res => {
        this.licenseDetail = res;
        this.selectedApps = this.licenseDetail.applicationName;
        this.apibundleService.hideSpinner('licenseView');  
        this.spinner = false;
      },
      err => {
        this.err = err;
        console.log(err);
        this.apibundleService.hideSpinner('licenseView');  
        this.spinner = false;
      }
    );
  }
  /*--------  END OF FUNCTION -------- */

}
