<section class="section-padding">
    <div class="container">
        <div class="row">
            <div class="col animate__animated animate__zoomIn animate__delay-0.5s">
                <p>
                    The Microsoft Power Platform delivers fundamental technology benefits that can improve business
                    agility, productivity and reduce costs. Most organizations say that accelerating digital innovation
                    and transformation is the number one reason they embrace low code application development. The
                    advice in this guidance will help you transform your organization. You are already an expert in your
                    business, Power Platform gives you the tools to take that expertise and solve business problems with
                    automation and apps tailored for your Business problems. To take advantage of this great potential,
                    ensure the Power Platform technologies is part of the Digital Transformation strategy of your
                    organization.
                </p>
                <article class="wow animate__animated animate__zoomIn animate__delay-0.5s">
                    <h3>Realizing our Vision</h3>
                    <p>Based on Microsoft’s experience with other customers who have successfully adopted the Power
                        Platform, there are a few steps that we must take to achieve success: </p>
                    <ol class="ml-5">
                        <li>Establish a Power Platform Center of Excellence (POWERCOE) </li>
                        <li>Establish Digital Cells and nurture the community of practitioners building and using the
                            platform </li>
                        <li>Clearly define the Roles and Responsibilities of all the personas involved in the platform
                        </li>
                    </ol>
                </article>
                <article class="wow animate__animated animate__zoomIn animate__delay-0.5s">
                    <h3>POWERCOE</h3>
                    <p>Establishing a Power Platform Center of Excellence (CoE) means investing in and nurturing organic
                        growth while maintaining governance and control. For many, the CoE is the first step in
                        fostering
                        greater creativity and innovation across the organization by empowering business units to
                        digitize
                        and automate their business processes, while maintaining the necessary level of central
                        oversight
                        and governance. </p>

                    <p>POWERCOE is Coca-Cola’s implementation of Power Platform Center of Excellence. POWERCOE is a
                        group of
                        people within Global IT and IS which aims to promote standards, consistency, and quality around
                        Power Platform technologies deployed in Coca-Cola. We aim to provide governance, training,
                        tools,
                        best practices and support for citizen developers across Coca-Cola. </p>
                </article>
                <article class="wow animate__animated animate__zoomIn animate__delay-0.5s">
                    <h3>POWERCOE is expected to: </h3>
                    <ul class="ml-5">
                        <li>
                            Secure applications and solutions by establishing Data Loss Prevention (DLP)
                            <link to DLP documentation> policies, managing Licenses
                            <link to licensing documentation>, and access to data sources. </li>

                        <li>Educate by providing documentation, best practices, and training in the form of online
                            classes,
                            hackathons, documents, videos, lunch and learns, and other events. </li>

                        <li>Nurture by establishing a community of Power Platform makers, evangelists, and advocates to
                            spread the knowledge and help users solve their business problems </li>

                        <li>Monitor the usage and adoption of Power Platform, see who is creating apps, what apps are
                            being
                            created and how they are used </li>

                        <li>Evolve and Improve our strategy and services based on the learnings and experiences from the
                            community </li>

                    </ul>
                </article>
                <article class="wow animate__animated animate__zoomIn animate__delay-0.5s">
                    <h3>You are a Developer, you just don’t know it </h3>
                    <p>The Microsoft Platform is comprised of Power Apps, Power Automate, Power Virtual Agents, and
                        Power
                        BI. Microsoft created these tools for every single business user to start digitizing our
                        business
                        processes and solving business problems without IT being a bottleneck.

                        Most business users have been using Excel, Forms, PowerPoint, Word, SharePoint and Teams to do
                        their
                        job. You will find that learning to make apps in the Power Platform is as easy. The investment
                        in
                        time and effort in learning these simple to use, yet very powerful digital tools will pay off
                        for
                        your own personal career and growth as leading companies and industries companies your digital
                        skillset to include your ability to make no-code to low-code applications using the Power
                        Platform.

                        For IT Professionals, the possibility to use the Power Platform seems to have no limits. You can
                        solve very complex problems with the use of these tools in conjunction with Microsoft Azure
                        Cloud
                        technologies.</p>
                    <img class="wow animate__animated animate__zoomIn animate__delay-0.5s" src="../../../assets/img/pages/strategy1.png" />
                </article>
                <article class="wow animate__animated animate__zoomIn animate__delay-0.5s">
                    <h3>Power Platform Personas </h3>
                    <p>There are a few key roles needed for the Power Platform community to function well. Those are
                        Maker,
                        Champion, Fusion Developer, Environment Administrator, IT Developer and Power Platform
                        Administrator. </p>

                    <div class="row">
                        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
                            <div class="text-center"><img class="wow animate__animated animate__zoomIn animate__delay-0.5s" src="../../../assets/img/pages/maker.png" alt="maker" /></div>
                            <h5 class="text-center">Maker</h5>
                            <ul>
                                <li>Builds components on Power Platform in service of other job function but does not
                                    work on the platform full-time.</li>
                                <li>Normally doesn’t use premium connectors, CDS, etc.</li>
                                <li>Makers build apps primary for their own personal productivity or for the use of a
                                    small team. </li>
                            </ul>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 wow animate__animated animate__zoomIn animate__delay-0.5s">
                            <div class="text-center"><img class="wow animate__animated animate__zoomIn animate__delay-0.5s" src="../../../assets/img/pages/champion.png" alt="Champion" /></div>
                            <h5 class="text-center">Champion</h5>
                            <ul>
                                <li>Power Platform evangelists and advocates.</li>
                                <li>Champions are aids in fostering the community of all Power Platform practitioners.
                                </li>
                                <li>Conducts training classes and help organize hackathons and events to promote the use
                                    and adoption </li>
                            </ul>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 wow animate__animated animate__zoomIn animate__delay-0.5s">
                            <div class="text-center"><img class="wow animate__animated animate__zoomIn animate__delay-0.5s" src="../../../assets/img/pages/focus.png" alt="Fusion Developer" /></div>
                            <h5 class="text-center">Fusion Developer</h5>
                            <ul>
                                <li>Dedicated to the solve business problems of a particular Digital Cell (business
                                    unit, business function, sub-function, department). </li>
                                <li>Proficient with Power Platform, Dynamics, CDS, Data Modeling, Azure Integration,
                                    Microsoft 365 Integration etc. CDS, Data Modeling, etc.</li>
                                <li>Mentors Makers and Champions within a Digital Cell. </li>
                            </ul>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 wow animate__animated animate__zoomIn animate__delay-0.5s">
                            <div class="text-center"><img class="wow animate__animated animate__zoomIn animate__delay-0.5s" src="../../../assets/img/pages/env.png" alt="Environment Adminitrator" /></div>
                            <h5 class="text-center">Environment Adminitrator</h5>
                            <ul>
                                <li>Dedicated to administers the environments within a Digital Cell</li>
                                <li>Reviews and approves license requests and access to environments they manage</li>
                                <li>Champion for security, governance and administration with a Digital Cell </li>
                            </ul>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
                            <div class="text-center"><img src="../../../assets/img/pages/developer.png" alt="IT Developer" /></div>
                            <h5 class="text-center">IT Developer</h5>
                            <ul>
                                <li>Full-time dedicated to IT / software function, and possesses specialized knowledge
                                    in some combination of the following: </li>
                                <li>Power Platform (Power Apps, Power Automate, PVA)</li>
                                <li> Web Development (HTML, JavaScript, CSS, XML) </li>
                                <li>API Development (GraphQL, REST, SOAP)</li>
                                <li>Cloud Technologies (Azure, AWS) </li>
                                <li>Database Development (SQL, NoSQL, Graph) </li>
                            </ul>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
                            <div class="text-center"><img src="../../../assets/img/pages/ppa.png" alt="Power Platform Administrator" /></div>
                            <h5 class="text-center">Power Platform Administrator</h5>
                            <ul>
                                <li>Full-time IT employee who is responsible for the central oversight and governance of
                                    the overall Power Platform deployments and adoption with Coca-Cola.</li>
                                <li>Manage licenses, creation of environments, and all the underlying administration
                                    activities.</li>
                                <li>Set data loss prevention policies, environment strategies, security policies, etc.
                                </li>
                            </ul>
                        </div>
                    </div>
                </article>
                <article class="wow animate__animated animate__zoomIn animate__delay-0.5s">
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8">
                            <h3>Digital Cells </h3>
                            <p>Digital Cells are the building blocks of the Power Platform community. Business Units, functions, and sub-functions, departments, or group of people task to solve a common business problem can form Digital Cells. Digital Cells contain the Makers, Champions, and Fusion Developers who effectively advocates, nurtures, develop apps using the Power Platform tools to improve and automate processes within their business unit, function, or sub-function. </p>
                            <p>Digital Cell leader, Environment Administrators work very closely with POWERCOE (Power Platform Center of Excellence) to make sure Makers and Fusion Developers operating within the digital cell adhere to guardrails, policies, and governance to ensure quality and security of the apps they build. </p>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                            <img src="../../../assets/img/pages/digitalcells.png" alt="digitalcells" />
                        </div>
                    </div>
                    <p>The proliferation of Digital Cells with the Makers, Champions, and Fusion Developers in it triggers the network effect. Digital Cells working closely with the POWERCOE and the rest of the Power Platform community makes it possible to turn our digital dreams into a reality. </p>
                     <div><img src="../../../assets/img/pages/powercoe.png" alt="powercoe" /></div>
                </article>
                
            </div>
        </div>
    </div>

</section>