import { Component, OnInit, Host, ViewChild } from '@angular/core';
import { ApibundleService } from '../../services/apibundle.service';
import { BroadcastService, MsalService } from '@azure/msal-angular';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { CommonService } from '../../services/common.service';
import { Location } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-req-approval',
  templateUrl: './req-approval.component.html',
  styleUrls: ['./req-approval.component.css']
})
export class ReqApprovalComponent implements OnInit {

  
  err;
  public requestList: any = [];  
  norecord:boolean = false;
  approvedList; 
  loadedkbList;  
  isUnAuthorized = false;   
  displayedColumns: string[] = ['environmentName', 'requestType', 'date', 'reqstatus', 'action'];
  limit: number = 5;
  requestDataSourceMobile: any;  
  isFilterData: boolean = false;
  @ViewChild(MatPaginator) paginators: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  spinner: boolean = false;
  spinnerName = "reqApproval";

  constructor(
    private apibundleService: ApibundleService,
    private broadcastService:BroadcastService,
    private router: Router,
    private commonService: CommonService,
    public location: Location,
    private ngxSpinner: NgxSpinnerService
  ) { 
    this.broadcastService.subscribe("msal:loginSuccess", payload => {     
     console.log(payload);
    });
  }

  ngOnInit() {   
    this.isApprover();
    window.scrollTo(0, 0);
  }

  isApprover() {
    this.apibundleService.showSpinner('reqApproval'); 
    this.spinner = true; 
    this.apibundleService.getUserAuthorization().subscribe(res => {
      if (res.approver)
        this.fetchRequestForApproval();
      else
        this.router.navigate(['/services']);
    },
      err => {
        this.err = err;
        this.apibundleService.hideSpinner('reqApproval');  
        this.spinner = false;
      }
    );
  }

  async approveOrRejectRequest(requestDetail, status: string){
    var reqObj = {
      "RequestId":"",
      "RequestType":"",
      "RequestStatus":""
    }
    reqObj.RequestId = requestDetail.requestId;
    reqObj.RequestType = requestDetail.requestType;
    reqObj.RequestStatus = status;
    requestDetail.reqstatus = status;
    this.approveOrRjectEnvReq(reqObj);

  }

  async approveOrRjectEnvReq(reqObj: any) {  
    this.ngxSpinner.show();
    this.apibundleService.approveOrRjectEnvReq(reqObj).subscribe(
      res => {
        this.requestList = this.requestList.filter(function(req) {
          return req.requestId != reqObj.RequestId;
      });
        this.formatData();
        this.ngxSpinner.hide();
        this.commonService.openSnackBar("Environment request has been " + reqObj.RequestStatus + " successfully", "OK");
      }, 
      err => {       
        this.err = err; 
        console.log(err);  
        this.ngxSpinner.hide(); 
      });

  }
  
  async fetchRequestForApproval() {    
    this.apibundleService.getAllRequestsToBeApproved().subscribe(
      res => {
        //this.requestList = res;
        this.requestList = this.commonService.sortDescByDate(res);  // sort by date in descending order
        this.requestList = this.commonService.changeDateFormatAndSetPendingStatus(this.requestList); //change date format and set the status to Pending 
        setTimeout(() => {
          this.formatData(); 
          this.apibundleService.hideSpinner('reqApproval');  
          this.spinner = false;
        }, 2000);
      if(this.requestList.length === 0){
        this.norecord = true;
      } else {
        this.norecord = false;       
      }
      }, 
      err => {       
        this.err = err; 
        console.log(err); 
        this.apibundleService.hideSpinner('reqApproval');  
        this.spinner = false;
      });
  } 

  formatData() {
    this.requestDataSourceMobile = new MatTableDataSource<any>(this.requestList); 
    this.requestDataSourceMobile.paginator = this.paginators;
    this.requestDataSourceMobile.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.requestDataSourceMobile.filter = filterValue; 
    if((this.requestDataSourceMobile.filteredData.length == 0) && filterValue.length > 0)
      this.isFilterData = true;
    else
      this.isFilterData = false;
  }

  doRefresh(event) {
    console.log('Begin async operation');   
    this.fetchRequestForApproval();
    setTimeout(() => {
      console.log('Async operation has ended');
      event.target.complete();
    }, 2000);
  }

  viewRequest(requestDetail){
    if(requestDetail.requestType == "Environment") {  
      this.router.navigate(['/services/environment/view/' + requestDetail.requestId]); 
    } else if (requestDetail.requestType == "License") {
      this.router.navigate(['/services/license/view/' + requestDetail.requestId]); 
    }
  }

}
