import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ApibundleService } from '../../services/apibundle.service';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css']
})
export class ServicesComponent implements OnInit, OnDestroy {

  services: any = [];
  err: any;
  permissions: any = [];
  error: boolean = false;
  errorMsg: string = '';
  spinnerName = "services";
  spinner = false;
  noRecords: boolean = false;
  cssClasses = 'col-xs-12 col-sm-6 col-md-3 col-lg-3';

  constructor(
    private router: Router,
    private apibundleService: ApibundleService,
  ) {



  }

  ngOnInit(): void {
    this.addRole();
  }
  addRole() {
    this.apibundleService.showSpinner('services');
    this.spinner = true;  
    this.apibundleService.getUserAuthorization().subscribe(res => {
      if (res.approver) {
      this.services = [
        {id:'1', title:'Environment Request', icon:'add_road', route:'services/environment/createrequest'},
        {id:'2', title:'License Request', icon:'how_to_reg', route:'services/license/createrequest'},   
        {id:'3', title:'My Requests', icon:'school', route:'services/myrequests'},
        {id:'4', title:'Approve Requests', icon:'book', route:'services/approverequest'},
        // {id:'5', title:'Report an Incident', icon:'build', route:'reportincident'},
        // {id:'6', title:'Service Request', icon:'settings', route:'service'},
      ];
      this.cssClasses = this.services && this.services.length > 3 ? 'col-xs-12 col-sm-6 col-md-3 col-lg-3' : 'col-xs-12 col-sm-6 col-md-4 col-lg-4';     
    } else {
        this.services = [
          {id:'1', title:'Environment Request', icon:'add_road', route:'services/environment/createrequest'},
          {id:'2', title:'License Request', icon:'how_to_reg', route:'services/license/createrequest'},        
          {id:'3', title:'My Requests', icon:'book', route:'services/myrequests'},
          // {id:'4', title:'Report an Incident', icon:'build', route:'reportincident'},
          // {id:'5', title:'Service Request', icon:'settings', route:'service'},
        ];
        this.cssClasses = this.services && this.services.length > 3 ? 'col-xs-12 col-sm-6 col-md-3 col-lg-3' : 'col-xs-12 col-sm-6 col-md-4 col-lg-4';     
      }
       
      this.apibundleService.hideSpinner('services');
      this.spinner = false;
    },
      err => {
        this.err = err;
        this.apibundleService.hideSpinner('services');
        this.spinner = false;
      }
    );
  }
  

  go(route) {
    this.router.navigateByUrl(route);
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.apibundleService.hideSpinner('services');
    this.spinner = false;
  }
}
