import { Component, OnInit, OnDestroy, HostListener, ElementRef, ViewChild } from '@angular/core';
import { MediaObserver ,MediaChange} from '@angular/flex-layout';
// import { MatCarousel,MatCarouselSlide,  MatCarouselComponent, MatCarouselSlideComponent } from '@ngmodule/material-carousel';
import { Subscription } from 'rxjs';
import { Router, NavigationEnd, NavigationStart} from '@angular/router';
import { BroadcastService, MsalService } from '@azure/msal-angular';
import { ApibundleService } from './services/apibundle.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { Logger, CryptoUtils } from 'msal';
import { MessageService } from './services/message.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'COE';
  isIframe = false;
  loggedIn = false; 
  err;
  mediaSub: Subscription;
  deviceXs: boolean;  
  deviceSm: boolean;
  deviceMd: boolean;
  deviceLg: boolean;
  device: boolean;
  direction: string;
  bannerId: string = 'intro';
  logoId: any;
  navigationMenu: any = [];
  isShow: boolean;
  topPosToStartShowing = 100;
  slides:any = [];  
  bannerImage:string = '';
  public user:string = 'Guest';
  public email:string = '';
  public isLoggedIn = false;
  base64String:string = '';
  bannerStyle:any;
  bannerText:boolean = false;
  bannerFor:string = '';
  logoname:string = 'powercoelogo.png';
  subscription;

  constructor(
    public mediaObserver: MediaObserver,
    private router: Router,
    private authService: MsalService,
    private broadcastService: BroadcastService,
    private apibundleService: ApibundleService,
    private permissionsService: NgxPermissionsService,
    private messageService:MessageService
    ){    

    this.messageService.getMessage().subscribe(async (res) => {
        this.logoname = res == false ? 'powercoelogo.png' : 'powercoelogowhite.png';
    })
    this.navigationMenu = [
      // {id:1, title:'Home', icon:'home', route:''},
      {id:2, title:'Services', icon:'design_services', route:'services'},
      {id:3, title:'Training', icon:'model_training', route:'training'},
      {id:4, title:'Documentation', icon:'dock', route:'documentation'},
      {id:5, title:'Articles', icon:'articles', route:'articles'},
      // {id:6, title:'Forums', icon:'person', route:'Forums'},
    ]
     this.slides = [
      {id:'1', image:'./assets/img/intro-bg.gif'},
      // {id:'2', image:'./assets/img/intro-bg1.jpg'}, 
      // {id:'3', image:'./assets/img/slides_01.jpg'},  
    ]   
   
  }  

  async getProfileImage(){
    this.apibundleService.getProfile().subscribe(
      response => {
        console.log(response);
        if(response.status === 200){
          this.base64String = "data:image/png;base64,"+ response[0];
          window.localStorage.setItem('COE_USER_PROFILE_PIC', "data:image/png;base64,"+ response[0]);
        } 
      },
      error => {
        console.log(error);
        if(error.status === 200){
          this.base64String = "data:image/png;base64,"+ error['error'].text;
          window.localStorage.setItem('COE_USER_PROFILE_PIC', "data:image/png;base64,"+ error['error'].text);         
        }
      }
    )
  }

  ngOnInit(){     

    this.isIframe = window !== window.parent && !window.opener;

    this.checkoutAccount();

    this.broadcastService.subscribe('msal:loginSuccess', (payload) => {
      this.checkoutAccount();
      if(this.loggedIn){
        window.localStorage.setItem('COE_OAUTH_ID_TOKEN', "Bearer " + payload.idToken.rawIdToken); 
        let user = payload.account.name.replace(' [C]','');       
        window.localStorage.setItem('COE_USER_NAME', user);
        window.localStorage.setItem('COE_USER_EMAIL', payload.account.userName);
        window.localStorage.setItem('COE_USER_LOGIN', 'success');
        this.getProfileImage();
      }      
    });

    this.broadcastService.subscribe('msal:loginFailure', (payload) => {       
      this.loggedIn = false;
      localStorage.clear();
      this.authService.loginRedirect();   
     });  


    this.authService.handleRedirectCallback((authError, response) => {
      if (authError) {
        console.error('Redirect Error: ', authError.errorMessage);
        localStorage.clear();
        return;
      }
       
      console.log('Redirect Success: ', response);
    });

    this.authService.setLogger(new Logger((logLevel, message, piiEnabled) => {
      console.log('MSAL Logging: ', message);
    }, {
      correlationId: CryptoUtils.createNewGuid(),
      piiLoggingEnabled: false
    }));  

    if(window.localStorage.getItem('COE_USER_LOGIN') === 'success'){
      this.user = window.localStorage.getItem('COE_USER_NAME') ? window.localStorage.getItem('COE_USER_NAME') : "";
      this.email  = window.localStorage.getItem('COE_USER_EMAIL') ? window.localStorage.getItem('COE_USER_EMAIL') : "";
      this.isLoggedIn = window.localStorage.getItem('COE_USER_LOGIN') === 'success' ? true : false; 
      let imgString = window.localStorage.getItem('COE_USER_PROFILE_PIC');
      this.base64String = imgString && imgString.length ? imgString : '';
    }
   
    this.mediaSub = this.mediaObserver.media$.subscribe(
      (result:MediaChange) => {    
      console.log(result.mqAlias);
      this.deviceXs = result.mqAlias == 'xs' ? true : false;
      this.deviceSm = result.mqAlias == 'sm' ? true : false;
      this.deviceMd = result.mqAlias == 'md' ? true : false;
      this.deviceLg = result.mqAlias == 'lg' ? true : false;
      const deviceWidth = ['xs','sm'];
      this.device = deviceWidth.includes(result.mqAlias) ? true : false;
      this.direction = result.mqAlias == 'xs' ? 'column' : 'row';
      this.logoId = result.mqAlias == 'xs' ? 'logo-mobile' : 'logo-desktop';      
    })
    
    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationStart) {
        //console.log(e);
        if (evt.url === "/" || evt.url === "/home" ) {
          this.bannerImage = 'splashcoe-bg.jpg';
          this.bannerStyle = 'home-banner animate__animated animate__zoomIn animate__delay-1s';                   
          this.bannerText = true;
          this.bannerFor = 'home';
        }
        if (evt.url === "/services" || evt.url.indexOf("/services/") > -1) {
          this.bannerImage = 'service-bg.jpg'
          this.bannerStyle = 'animate__animated animate__zoomIn animate__delay-1s';
          this.bannerText = true;
          this.bannerFor = 'services';
        }
        if (evt.url === "/articles" || evt.url.indexOf("/articles-details/") > -1) {
          this.bannerImage = 'articles-bg.jpg'
          this.bannerStyle = 'animate__animated animate__zoomIn animate__delay-1s';
          this.bannerText = true;
          this.bannerFor = 'articles';
        }
        if (evt.url === "/documentation" || evt.url.indexOf("/documentation/") > -1) {
          this.bannerImage = 'docs-bg.jpg'
          this.bannerStyle = 'animate__animated animate__zoomIn animate__delay-1s';
          this.bannerText = true;
          this.bannerFor = 'documentation';
        }
        if (evt.url === "/training" || evt.url.indexOf("/training/") > -1) {
          this.bannerImage = 'training-bg.jpg'
          this.bannerStyle = 'animate__animated animate__zoomIn animate__delay-1s';
          this.bannerText = true;
          this.bannerFor = 'training';
        }        
        if (evt.url === "/aboutus") {
          this.bannerImage = 'aboutus-bg.jpg'
          this.bannerStyle = 'animate__animated animate__zoomIn animate__delay-1s';
          this.bannerText = true;
          this.bannerFor = 'aboutus'; 
        }
        if (evt.url === "/strategyandvision") {
          this.bannerImage = 'vision-bg.jpg'
          this.bannerStyle = 'animate__animated animate__zoomIn animate__delay-0,5s';
          this.bannerText = true;
          this.bannerFor = 'strategyandvision'; 
        }
        if (evt.url === "/getstarted") {
          this.bannerImage = 'getstarted-bg.jpg'
          this.bannerStyle = 'animate__animated animate__zoomIn animate__delay-1s';
          this.bannerText = true;
          this.bannerFor = 'getstarted'; 
        }
        if (evt.url === "/natureandeducate") {
          this.bannerImage = 'natureandeducate-bg.jpg'
          this.bannerStyle = 'animate__animated animate__zoomIn animate__delay-1s';
          this.bannerText = true;
          this.bannerFor = 'natureandeducate'; 
        }
        
      } else if (!(evt instanceof NavigationEnd)) {
          return;
      }     
      window.scrollTo(0, 0)
  });
  
  }

  @HostListener('window:scroll')
  checkScroll() {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

    //console.log('[scroll]', scrollPosition);
    
    if (scrollPosition >= this.topPosToStartShowing) {
      this.isShow = true;
    } else {
      this.isShow = false;
    }
   
  }

  // TODO: Cross browsing
  gotoTop() {
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    });
  }
 

  go(route){
    this.router.navigateByUrl(route);
  }

  checkoutAccount() {
    this.loggedIn = !!this.authService.getAccount();
  }

  login() {
    //window.localStorage.clear();
    const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

   if (isIE) {
      this.authService.loginRedirect();
    } else {
      this.authService.loginRedirect();
    }
  }

  logout() {
    window.localStorage.clear();
    this.authService.logout();
  }

  ngOnDestroy(){
    this.mediaSub.unsubscribe();
    this.broadcastService.getMSALSubject().next(1);
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
