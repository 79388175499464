<section class="section-padding">
    <div class="container">
        <div class="row">
            <div class="col">
                <form class="example-form animate__animated animate__zoomIn animate__delay-0.5s" style="margin:5%;">
                    <h4 style="text-align:left;margin:0px;">VIEW ENVIRONMENT REQUEST</h4>
                    <hr>
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 custom-label"><span>Purpose</span>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <mat-label>{{requestDetail.purpose}}</mat-label>

                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 custom-label"><span>Business Unit /
                                Function</span>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <mat-label>{{requestDetail.bu}}</mat-label>

                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 custom-label"><span>Environment Name</span>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">

                            <mat-label>{{requestDetail.environmentName}}</mat-label>

                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 custom-label"><span>Environment Type</span>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">

                            <mat-label>{{requestDetail.environmentType}}</mat-label>

                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 custom-label"><span>Environment
                                Landscape</span>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">

                            <mat-label>{{requestDetail.environmentLandscape}}</mat-label>

                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 custom-label"><span>Environment Region</span>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">

                            <mat-label>{{requestDetail.region}}</mat-label>

                        </div>
                    </div>

                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 custom-label"><span>Environment
                                Currency</span>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <mat-label>{{requestDetail.currency}}</mat-label>

                        </div>
                    </div>

                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 custom-label"><span>Require Common Data
                                Service
                                (CDS)?</span>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">

                            <mat-label>{{requestDetail.cds}}</mat-label>

                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 custom-label"><span>Connectors</span>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">

                            <mat-chip-list #chipListCon>
                                <mat-chip *ngFor="let item of selectedConnectors" [selectable]="true"
                                    [removable]="true">
                                    {{item}}
                                </mat-chip>
                            </mat-chip-list>

                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 custom-label"><span>Will the Environment
                                contain
                                or
                                process Highly Restricted
                                information?"</span>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">

                            <mat-label>{{requestDetail.restrictedinformation}}</mat-label>

                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 custom-label"><span>Environment
                                Administration</span>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">

                            <mat-chip-list #chipList>
                                <mat-chip *ngFor="let item of selectedAdmins" [selectable]="true" [removable]="true">
                                    {{item.userName}}
                                </mat-chip>
                            </mat-chip-list>

                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 custom-label"><span>Information Protection
                                Policy
                                Acknowledgement</span>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">

                            <mat-label>{{requestDetail.ippAcceptance}}</mat-label>

                        </div>
                    </div>
                    <div class="row">
                        <div class="col text-center">
                            <div class="norecord">
                                <button mat-raised-button color="dark" (click)="location.back()"
                                    style="width : 8em;;background-color: #333;color:#fff;">Back</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>