<section class="section-padding">
    <div class="container">
        <div class="row">
            <div class="m-auto col-md-10 col-lg-10 col-xs-12 col-sm-12">
                <form class="example-form animate__animated animate__zoomIn animate__delay-0.5s" #form="ngForm" (ngSubmit)="processForm(form)">
                    <h5 class="text-left;m-0;">ENVIRONMENT REQUEST</h5>
                    <hr>
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 custom-label"><span>Purpose</span>
                            <span class="required-asterisk">*</span>
                            <mat-icon mat-raised-button
                                matTooltip="Describe to purpose and the business justification for requesting this environment"
                                aria-label="Purpose">info_outline</mat-icon>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <mat-form-field appearance="outline" style="width: 300px;">
                                <textarea [(ngModel)]="requestDetail.Purpose" matTextareaAutosize matAutosizeMinRows="3"
                                    matAutosizeMaxCols="" [formControl]="purpose" matInput></textarea>
                                <mat-error *ngIf="purpose.invalid">{{getErrorMessage()}}</mat-error>
                            </mat-form-field>

                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 custom-label"><span>Business Unit /
                                Function</span>
                            <span class="required-asterisk">*</span>
                            <mat-icon mat-raised-button
                                matTooltip="Select the Business Unit (BU), Function, or Department this environment will be used for"
                                aria-label="Purpose">info_outline</mat-icon>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">

                        <mat-form-field appearance="outline" style="width: 300px;">
                            <mat-select  [(ngModel)]="requestDetail.BU" [formControl]="busUnit">
                                <mat-option (onSelectionChange)="formEnvNameBu(businessUnit.abbrivation)"
                                    *ngFor="let businessUnit of businessUnits" [value]="businessUnit.businessUnitName">
                                    {{businessUnit.businessUnitName}} ({{businessUnit.abbrivation}})
                                </mat-option>
                            </mat-select>

                                <mat-error *ngIf="busUnit.invalid">{{getErrorMessage()}}</mat-error>
                            </mat-form-field>

                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 custom-label"><span>Environment Name</span>
                            <span class="required-asterisk">*</span>
                            <mat-icon mat-raised-button matTooltip="Give an environment name" aria-label="Purpose">
                                info_outline
                            </mat-icon>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">

                            <mat-form-field appearance="outline" style="width: 300px;">
                                <input [(ngModel)]="requestDetail.EnvironmentName" [formControl]="envName" matInput>
                                <mat-error *ngIf="envName.invalid">{{getErrorMessage()}}</mat-error>
                            </mat-form-field>

                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 custom-label"><span>Environment Type</span>
                            <span class="required-asterisk">*</span>
                            <mat-icon mat-raised-button
                                matTooltip="Select the type of Power Platform environment is requested"
                                aria-label="Purpose">info_outline</mat-icon>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">

                        <mat-form-field appearance="outline" style="width: 300px;">
                            <mat-select [(ngModel)]="requestDetail.EnvironmentType" [formControl]="envType">
                                <mat-option (onSelectionChange)="setEnvLandscape(enviType)" *ngFor="let enviType of envTypeList"
                                    [value]="enviType.environmenttype">
                                    {{enviType.environmenttype}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="envType.invalid">{{getErrorMessage()}}</mat-error>
                        </mat-form-field>

                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 custom-label"><span>Environment
                                Landscape</span>
                            <span class="required-asterisk">*</span>
                            <mat-icon mat-raised-button
                                matTooltip="Select the type of Power Platform environment is requested"
                                aria-label="Purpose">info_outline</mat-icon>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">

                        <mat-form-field appearance="outline" style="width: 300px;">
                            <mat-select [disabled]="isEnvLandEnable" [(ngModel)]="requestDetail.EnvironmentLandscape"
                                [formControl]="envLand">
                                <mat-option (onSelectionChange)="formEnvNameLand(evnviLand.abbreviation)"
                                    *ngFor="let evnviLand of envLandscapeList" [value]="evnviLand.environmentlandscape">
                                    {{evnviLand.environmentlandscape}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="envLand.invalid">{{getErrorMessage()}}</mat-error>
                        </mat-form-field>

                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 custom-label"><span>Environment Region</span>
                            <span class="required-asterisk">*</span>
                            <mat-icon mat-raised-button
                                matTooltip="Select the country of Power Platform environment is requested"
                                aria-label="Purpose">info_outline</mat-icon>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">

                        <mat-form-field appearance="outline" style="width: 300px;">
                            <mat-select [(ngModel)]="requestDetail.Region" [formControl]="envRegion">
                                <mat-option (onSelectionChange)="formEnvNameReg(enviRegion.abbreviation)"
                                    *ngFor="let enviRegion of envRegionList" [value]="enviRegion.region">
                                    {{enviRegion.region}} ({{enviRegion.abbreviation}})
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="envRegion.invalid">{{getErrorMessage()}}</mat-error>
                        </mat-form-field>

                        </div>
                    </div>

                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 custom-label"><span>Environment
                                Currency</span>
                            <span class="required-asterisk">*</span>
                            <mat-icon mat-raised-button matTooltip="The base currency for reporting in this environment"
                                aria-label="Purpose">info_outline</mat-icon>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">

                            <mat-form-field appearance="outline" style="width: 300px;">
                                <mat-select [(ngModel)]="requestDetail.Currency" [formControl]="envCurrency">
                                    <mat-option *ngFor="let enviCur of evnCurrencyList" [value]="enviCur.currency">
                                        {{enviCur.currency}}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="envCurrency.invalid">{{getErrorMessage()}}</mat-error>
                            </mat-form-field>

                        </div>
                    </div>

                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 custom-label"><span>Require Common Data
                                Service
                                (CDS)?</span><span class="required-asterisk">*</span>
                            <mat-icon mat-raised-button matTooltip="Require Common Data Service (CDS)?"
                                aria-label="Purpose">
                                info_outline</mat-icon>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">

                            <mat-radio-group [(ngModel)]="requestDetail.CDS" [formControl]="reqCds"
                                aria-label="Select an option">
                                <mat-radio-button value="Yes">Yes</mat-radio-button>&nbsp;&nbsp;&nbsp;
                                <mat-radio-button value="No">No</mat-radio-button>
                                <mat-error class="radio-error" *ngIf="isSubmitted && reqCds.invalid">
                                    {{getErrorMessage()}}
                                </mat-error>
                            </mat-radio-group>

                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 custom-label"><span>Connectors</span>
                            <span class="required-asterisk">*</span>
                            <mat-icon mat-raised-button matTooltip="Select the Connectors" aria-label="Purpose">
                                info_outline
                            </mat-icon>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">

                            <mat-form-field style="width: 300px;" appearance="outline">
                                <mat-chip-list #chipListCon>
                                    <mat-chip *ngFor="let item of selectedConnectItems" [selectable]="true"
                                        [removable]="true" (removed)="removeConnector($event)">
                                        {{item}}
                                        <mat-icon (click)="removeConnector(item)" matChipRemove>cancel</mat-icon>
                                    </mat-chip>
                                    <input #connectInput [formControl]="connectors" [matAutocomplete]="autos"
                                        [matChipInputFor]="chipListCon"
                                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                        [matChipInputAddOnBlur]="true" required (keyup)="filterConnectors($event)">
                                </mat-chip-list>
                                <mat-autocomplete #autos="matAutocomplete"
                                    (optionSelected)="addSelectConnector($event)">
                                    <mat-option *ngFor="let connect of filteredConnectors"
                                        [value]="connect.connectorName">
                                        {{connect.connectorName}}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                            <mat-error class="radio-error" *ngIf="isSubmitted && selectedConnectItems.length == 0">
                                {{getErrorMessage()}}</mat-error>


                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 custom-label"><span>Will the Environment
                                contain
                                or
                                process Highly Restricted
                                information?"</span>
                            <span class="required-asterisk">*</span>
                            <mat-icon mat-raised-button
                                matTooltip="Will the Environment contain or process Highly Restricted information?"
                                aria-label="Purpose">info_outline</mat-icon>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">

                            <mat-radio-group [(ngModel)]="requestDetail.Restrictedinformation"
                                [formControl]="highRestInfo" aria-label="Select an option">
                                <mat-radio-button value="Yes">Yes</mat-radio-button>&nbsp;&nbsp;&nbsp;
                                <mat-radio-button value="No">No</mat-radio-button>
                                <mat-error class="radio-error" *ngIf="isSubmitted && highRestInfo.invalid">
                                    {{getErrorMessage()}}
                                </mat-error>
                            </mat-radio-group>

                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 custom-label"><span>Environment
                                Administration</span><span class="required-asterisk">*</span>
                            <mat-icon mat-raised-button matTooltip="Who will be the admiinistrator of the environment"
                                aria-label="Purpose">info_outline</mat-icon>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">

                            <mat-form-field style="width: 300px;" appearance="outline">
                                <mat-chip-list #chipList>
                                    <mat-chip *ngFor="let item of selectedAdminItems" [selectable]="true"
                                        [removable]="true" (removed)="removeAdmin($event)">
                                        {{item.userName}}
                                        <mat-icon (click)="removeAdmin(item)" matChipRemove>cancel</mat-icon>
                                    </mat-chip>
                                    <input #adminInput [formControl]="envAdmin" [matAutocomplete]="auto"
                                        [matChipInputFor]="chipList"
                                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                        [matChipInputAddOnBlur]="true" required (keyup)="getEnvAdmins($event)">
                                </mat-chip-list>
                                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="addSelectAdmin($event)">
                                    <mat-option *ngFor="let envAdministrator of envAdminList"
                                        [value]="envAdministrator">
                                        {{envAdministrator.userName}}
                                    </mat-option>
                                </mat-autocomplete>
                                <mat-spinner *ngIf="isConnectorLoad" [diameter]="30"></mat-spinner>
                            </mat-form-field>
                            <mat-error class="radio-error" *ngIf="isSubmitted && selectedAdminItems.length == 0">
                                {{getErrorMessage()}}</mat-error>
                            <mat-error class="radio-error" *ngIf="selectedAdminItems.length > 3">
                                Maximum 3 administrator allowed </mat-error>


                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 custom-label"><span>Information Protection
                                Policy
                                Acknowledgement</span>
                            <span class="required-asterisk">*</span>
                            <mat-icon mat-raised-button
                                matTooltip="Do you accept the responsibility for managing access right to your environment and ensuring that your environment is in full compliance with the requirmments established by the Information Protection Policy (IPP)? This includes, but it is not limited to, retention of documentation required by the Companies commpliance organization related to the user access (e.g. evidence of periodic access reviews)"
                                aria-label="Purpose">info_outline</mat-icon>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">

                            <mat-radio-group (change)="checkAcceptSelected($event)"
                                [(ngModel)]="requestDetail.IPPAcceptance" [formControl]="ippPolicyStmt"
                                aria-label="Select an option">
                                <mat-radio-button value="Yes">I Accept</mat-radio-button>&nbsp;&nbsp;&nbsp;
                                <mat-radio-button value="No">I Do Not Accept </mat-radio-button>
                                <mat-error class="radio-error" *ngIf="isSubmitted && ippPolicyStmt.invalid">
                                    {{getErrorMessage()}}
                                </mat-error>
                                <mat-error class="radio-error" *ngIf="isIppPolicytSelectedYes">Please accept Information
                                    Protection
                                    Policy Acknowledgement to submit</mat-error>
                            </mat-radio-group>

                        </div>
                    </div>

                    <div class="row mt-4">
                        <div class="col text-center">
                            <button mat-raised-button color="dark" class="back-button"
                                (click)="location.back()">Back</button>&nbsp;&nbsp;&nbsp;
                            <button mat-raised-button color="warn" [disabled]="isIppPolicytSelectedYes"
                                style="width : 8em;;">Submit</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>