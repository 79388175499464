<section class="section-padding">
  <div class="container">
    <div class="row">
      <div class="col" *ngIf="!norecord">
        I found below environment(s) which match your need. If its Ok, please click on one the below environment to
        request access, or click on ‘Request New Environment’ for you.
      </div>
    </div>
    <div class="row" *ngIf="!spinner">
      <div class="col">
        <div class="mat-tab-container animate__animated animate__zoomIn animate__delay-0.5s" *ngIf="!norecord">
          <mat-table [dataSource]="dataSource" matSort>

            <!-- Name Column -->
            <ng-container matColumnDef="environment">
              <mat-header-cell *matHeaderCellDef mat-sort-header
                style="flex-direction: unset;justify-content: left;padding-left:15px;"> ENVIRONMENTS </mat-header-cell>
              <mat-cell *matCellDef="let element" (click)="submitRequest(element)"> {{element.name}} </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;" class="element-row"></mat-row>
          </mat-table>
          <mat-paginator *ngIf="!norecord" #paginatortable [pageSize]="5" [pageSizeOptions]="[5, 10, 20]"
            [showFirstLastButtons]="true">
          </mat-paginator>
        </div>

        <div class="norecord" *ngIf="norecord">
          <mat-label>We could not find a matching environment for you. Please choose to create a new one.</mat-label>
        </div>

        <div class="row mt-4 mb-4">
          <div class="col text-center">
            <button mat-raised-button color="dark" class="back-button"
              (click)="location.back()">Back</button>&nbsp;&nbsp;&nbsp;
            <button mat-raised-button color="warn" (click)="submitRequest('New')" style="width : 17em;">New Environment
              Request</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
