export class RequestDetail {
      partitionKey: string = 'SBDEnvTest';
      rowKey: string = 'SBDEnv';
      admin: any;
      bu: string = '';
      cds: string = '';
      connectors: any;
      currency: string = '';
      environmentLandscape: string = '';
      environmentType: string = '';
      existingEnvironment: string = 'env 1';
      ippAcceptance: string = '';
      purpose: string = '';
      region: string = '';
      reqstatus: string = 'New';
      restrictedinformation: string = '';
      username: string = '';
      environmentid: string = '';
      environmentName: string = '';
      interactedvia: string = '';
      timestamp: any;
}
    
