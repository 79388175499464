export class LicenseDetailResponse {
      partitionKey: string = "New";
      rowKey: string = "NAN";
      environmentName: string = "";
      applicationName: any = [];
      licenseNumber: number;
      businessUnit: string = "";
      environmentOwner: string = "";
      userName: string = "";
      requestStatus: string = "";
      Interactedvia: string = "Application";
      timestamp: string = "";

}
    
