import { Component, OnInit } from '@angular/core';
import { ApibundleService } from '../../services/apibundle.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { BroadcastService, MsalService } from '@azure/msal-angular';
import { Observable } from 'rxjs';
import { RequestDetailRequest } from '../../interfaces/requestDetailRequest.model';
import { CommonService } from '../../services/common.service';
import { RequestDetail } from '../../interfaces/requestDetail.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';

@Component({
  selector: 'app-req-view',
  templateUrl: './req-view.component.html',
  styleUrls: ['./req-view.component.css']
})
export class ReqViewComponent implements OnInit {
  
  ngForm: FormGroup;
  loading: any;
  reqFromUrl: string = "";
  requestDetail = new RequestDetail();
  public disabled: boolean = false;
  public isEnableApprove: boolean = false;
  err: any;
  selectedConnectors: any = [];
  selectedAdmins: any = [];
  spinner: boolean = false;
  spinnerName = "reqView";

  constructor(
    private apibundleService: ApibundleService,
    private router: Router,
    private route: ActivatedRoute,
    private broadcastService: BroadcastService,
    private authService: MsalService,
    public location: Location
  ) {
      this.reqFromUrl = this.route.snapshot.paramMap.get('reqId');
  }

  ngOnInit() {
    this.fetchRequest(this.reqFromUrl);
  }


  /*--------  FUNCTION TO GET REQUEST USING REQUEST ID -------- */
  async fetchRequest(reqId) {
    this.apibundleService.showSpinner('reqView'); 
    this.spinner = true; 
    this.apibundleService.getRequestById(reqId).subscribe(
      res => {
        this.requestDetail = res[0];
        this.selectedAdmins = this.requestDetail.admin;
        this.selectedConnectors = this.requestDetail.connectors;
        this.apibundleService.hideSpinner('reqView');  
        this.spinner = false;
      },
      err => {
        this.err = err;
        console.log(err);
        this.apibundleService.hideSpinner('reqView');  
        this.spinner = false;
      }
    );
  }
  /*--------  END OF FUNCTION -------- */

}
