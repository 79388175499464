import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { MessageService } from '../../services/message.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, OnDestroy {
  homelist;
  constructor(
    private router: Router,
    private messageService: MessageService,
    private spinner: NgxSpinnerService
    ) {
      this.messageService.getMessage().subscribe(message => {
        if (message == this.messageService.searchText) {
          console.log(message);
        }
      });
      this.homelist = [
        {id:'1', title:'Strategy and Vision', icon:'explore', route:'strategyandvision', shortDes:'Leading the adoption of the Microsoft Power Platform across the Coca-Cola system.'},
        {id:'2', title:'Nurture and Educate', icon:'model_training', route:'natureandeducate', shortDes:' Upskill our digital capabilities by training, educating, and empowering business users.'},   
        {id:'3', title:'Administration and Governance', icon:'miscellaneous_services', route:'documentation', shortDes:'Implement guardrails, policies and processess for users to create with confidence and ease.'},
        // {id:'4', title:'Support and Operations', icon:'support', route:'', shortDes:'Provide Level 2/3 support for incidents, requests and manage our relationship with Microsoft.'},        
      ];
    }

  ngOnInit(): void {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0)
  });
  }

  go(route){
    this.router.navigateByUrl('/'+route);
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.spinner.hide();
  }
}
