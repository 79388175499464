import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpClient,
  HttpHeaders,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';

const reqHeader = new HttpHeaders({
  'No-Auth': 'True'
});

const reqHeaderForGit = new HttpHeaders({
  'Authorization': 'True'
});

const gitHubHeader = new HttpHeaders({
  'Authorization': `Basic`,
});

@Injectable({
  providedIn: 'root'
})
export class ApibundleService {

  apiRoot: string = environment.API_ROOT_URL;
  apiRootApp: string = environment.API_ROOT_APP_URL;
  post: any = [];
  posts: any = [];
  profile: any;

  constructor(
    private http: HttpClient,
    private spinner: NgxSpinnerService
  ) {
    // headers_object.append("Authorization", "Basic " + btoa("joneskebhart:Nihart9787gb!"));
  }

  showSpinner(name: string) {
    this.spinner.show(name);
  }

  hideSpinner(name: string) {
    this.spinner.hide(name);
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please wait, relogin again.');
  };

  /* -------- API CALL TO CHECK WHETHER AN USER IS APPROVER OR NOT -------- */
  getUserAuthorization(): Observable<any> {
    return this.http.get<any>(
      `${this.apiRootApp}approver`,
    ).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  /* -------- END OF API -------- */

  /* -------- API CALL TO GET ALL REQUEST FOR A PARTICULAR USER -------- */
  getAllRequest(): Observable<any> {
    return this.http.get<any>(
      `${this.apiRoot}environment/getrequests`,
    ).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  /* -------- END OF API -------- */

  /* -------- API CALL TO GET ALL PENDIING REQUEST FOR APPROVAL -------- */
  getAllRequestsToBeApproved(): Observable<any> {
    return this.http.get<any>(
      `${this.apiRoot}environment/GetPendingApprovals`,
    ).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  /* -------- END OF API -------- */

  /* -------- API CALL TO CREATE REQUEST WITH NEW ENVIRONMENT -------- */
  createRequestForNewEnvironment(formData): Observable<any> {
    return this.http.post<any>(
      `${this.apiRoot}environmentrequest/environmentcreaterequest`,
      formData,
    ).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  /* -------- END OF API -------- */

  /* -------- API CALL TO CREATE REQUEST WITH EXISTING ENVIRONMMENT-------- */
  createRequestForExistingEnvironment(formData): Observable<any> {
    return this.http.post<any>(
      `${this.apiRoot}environmentrequest/environmentaccessrequest`,
      formData,
    ).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  /* -------- END OF API -------- */

  /* -------- API CALL TO GET CONNECTORS -------- */
  getConnectors(): Observable<any> {
    return this.http.get<any>(
      `${this.apiRoot}Connector/GetConnectors`,
    ).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  /* -------- END OF API -------- */

  /* -------- API CALL TO GET ENVIRONMENT ADMINS BASED ON SEARCH DATA -------- */
  getEnvAdmins(searchData): Observable<any> {
    return this.http.get<any>(
      `${this.apiRoot}User/GetUsers?givenName=` + searchData,
    ).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  /* -------- END OF API -------- */

  /* -------- API CALL TO GET ENVIRONMENTS BASED ON CONNECTORS -------- */
  getEnvironments(body): Observable<any> {
    return this.http.post<any>(
      `${this.apiRoot}environment/GetEnvironmentsList`,
      body,
    ).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  /* -------- END OF API -------- */

  /* -------- API CALL TO APPROVE OR REJECT NEW ENVIRONMENT REQUEST -------- */
  approveOrRjectEnvReq(requestData): Observable<any> {
    return this.http.post<any>(
      `${this.apiRoot}requestapproval/sendrequestapproval`,
      requestData,
    ).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  /* -------- END OF API -------- */


  /* -------- API CALL TO GET ENVIRONMENT REQUEST BY ID -------- */
  getRequestById(reqId): Observable<any> {
    return this.http.get<any>(
      `${this.apiRoot}environment/GetRequests/` + reqId,
    ).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  /* -------- END OF API -------- */

  /* -------- API CALL TO GET BUSINESS UNIT -------- */
  getBusinessUnits(): Observable<any> {
    return this.http.get<any>(
      `${this.apiRoot}Business/GetBusinessUnits`,
    ).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  /* -------- END OF API -------- */

  /* -------- API CALL TO GET REGIONS -------- */
  getRegions(): Observable<any> {
    return this.http.get<any>(
      `${this.apiRoot}Business/GetRegionData`,
    ).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  /* -------- END OF API -------- */

  /* -------- API CALL TO GET ENVIRONMENT TYPES -------- */
  getEnvTypes(): Observable<any> {
    return this.http.get<any>(
      `${this.apiRoot}environmenttype/getenvironmenttypes`,
    ).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  /* -------- END OF API -------- */

  /* -------- API CALL TO GET CURRENCIES -------- */
  getCurrencies(): Observable<any> {
    return this.http.get<any>(
      `${this.apiRoot}currency/getenvironmentcurrencies`,
    ).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  /* -------- END OF API -------- */

  /* -------- API CALL TO GET ENVIRONMENT TYPES -------- */
  getEnvLandscapesByEnvId(id): Observable<any> {
    return this.http.get<any>(
      `${this.apiRoot}environmentlandscape/getenvlandscapebyid?id=` + id,
    ).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  /* -------- END OF API -------- */


  /* -------- API CALL TO GET BUSINESS UNIT -------- */
  getLicenseEnvironmentList(): Observable<any> {
    return this.http.get<any>(
      `${this.apiRoot}License/GetAllEnvironmentsList`,
    ).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  /* -------- END OF API -------- */

  /* -------- API CALL TO GET ALL POWER APPS BY ENVIRONMENT NAME -------- */
  getPowerAppByEnvName(envName): Observable<any> {
    return this.http.get<any>(
      `${this.apiRoot}License/GetAllPowerApps?envName=` + envName,
    ).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  /* -------- END OF API -------- */

  /* -------- API CALL TO GET ALL REQUEST FOR A PARTICULAR USER -------- */
  getAllLicenseRequest(): Observable<any> {
    return this.http.get<any>(
      `${this.apiRoot}license/getrequests`,
    ).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  /* -------- END OF API -------- */

  /* -------- API CALL TO CREATE REQUEST WITH NEW ENVIRONMENT -------- */
  createLicenseRequest(formData): Observable<any> {
    return this.http.post<any>(
      `${this.apiRoot}License/LicenseCreateRequest`,
      formData,
    ).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  /* -------- END OF API -------- */

  /* -------- API CALL TO GET ALL REQUEST FOR A PARTICULAR USER -------- */
  getMyRequests(): Observable<any> {
    return this.http.get<any>(
      `${this.apiRoot}environment/getmyrequests`,
    ).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  /* -------- END OF API -------- */

  /* -------- API CALL TO GET LICENSE BY ID -------- */
  getLicenseById(reqId): Observable<any> {
    return this.http.get<any>(
      `${this.apiRoot}License/getLicenseDetails?id=` + reqId,
    ).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  /* -------- END OF API -------- */

  /* -------- API CALL TO GET BUSINNESS UNIT BY ABBRIVIATION-------- */
  getBussUnitByAbbr(busUnitAbbr): Observable<any> {
    return this.http.get<any>(
      `${this.apiRoot}Business/GetBusinessUnitsByAbb?name=` + busUnitAbbr,
    ).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }
  /* -------- END OF API -------- */


  public getProfile(): Observable<any> {
    return new Observable<any>(observer => {
      this.loadProfile().subscribe(
        response => {
          if (response) {
            this.profile = response[0];
            observer.next(this.profile);
            observer.complete();
          } else {
            observer.error(response);
          }
        },
        error => {
          observer.error(error);
        }
      )

    });
  }

  loadProfile(): Observable<any> {
    let url = "https://globalengg-ko-appservc-coepowerapp.azurewebsites.net/api/UserDetails/GetProfile";
    return this.http.get<any>(url);
  }

  getGitHub(): Observable<any> {
    let url = this.apiRoot + "Github/GetGithubMenu?docName=github";
    return this.http.get<any>(url).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getGitHubDetails(docLink: string): Observable<any> {
    let url = this.apiRoot + "Github/GetGithubDocuments?fileName=" + docLink + "&docName=github";
    return this.http.get<any>(url).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }


  getArticles(categoryId): Observable<any> {
    //let url = window.location.origin+"/assets/articledata.json";
    let url = this.apiRoot + "wpposts/GetPostsbyCategory?category="+categoryId;
    return this.http.get<any>(
      url
    ).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }


  getArticleDetail(id): Observable<any> {   
    let url = this.apiRoot + "wpposts/getpostbyid?id=" + id
    return this.http.get<any>(
      url
    ).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }


  getArticlesByTags(tagId: number): Observable<any> {
    let url = this.apiRoot + "wpposts/GetPostsbyTags?tags=" + tagId;
    return this.http.get<any>(url).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }

  getArticlesByTagsAndCategory(tagId: number, categoryId: number): Observable<any> {
    let url = this.apiRoot + "wpposts/GetPostsbyTagsCategory?tags=" + tagId + "&category=" + categoryId;
    return this.http.get<any>(url).pipe(
      retry(1),
      catchError(this.handleError)
    );
  }      
    
}


