import { CarouselModule } from 'ngx-bootstrap/carousel';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatCarouselModule } from '@ngmodule/material-carousel';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FilterPipe } from './common/filter.pipe';
import { environment } from '../environments/environment';
import { MaterialModule } from './common/material-module';
import { FooterComponent } from './components/footer/footer.component';
import { CoeAuthGuard } from './guards/coe-Auth.guard';
import { CoeAuthInterceptor } from './interceptors/coe-auth.interceptor';
import { CoeAuthService } from './services/coeauth.service';
import { LazyLoadImageModule } from 'ng-lazyload-image';

// import { HeaderComponent } from './components/header/header.component';
import { ArticlesComponent } from './components/articles/articles.component';
import { CarsoalComponent } from './components/carsoal/carsoal.component';
import { ServicesComponent } from './components//services/services.component';
import { DocumentationComponent } from './components/documentation/documentation.component';
import { HomeComponent } from './components/home/home.component';
import { TrainingComponent } from './components/training/training.component';
import { AboutusComponent } from './components/aboutus/aboutus.component';
import { ReqEnvironmentComponent } from './components/req-environment/req-environment.component';
import { ReqCreationComponent } from './components/req-creation/req-creation.component';

import { NgxPermissionsModule } from 'ngx-permissions';
import { NgxSpinnerModule } from 'ngx-spinner';
import {
  MsalModule,
  MsalInterceptor,
  MSAL_CONFIG,
  MSAL_CONFIG_ANGULAR,
  MsalService,
  MsalAngularConfiguration
} from '@azure/msal-angular';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { Configuration } from 'msal';
import { LoginComponent } from './components/login/login.component';
import { ReqDashboardComponent } from './components/req-dashboard/req-dashboard.component';
import { DatePipe } from '@angular/common';
import { ReqApprovalComponent } from './components/req-approval/req-approval.component';
import { ArticleDetailsComponent } from './components/article-details/article-details.component';
import { SearchComponent } from './components/search/search.component';
import { LicenseCreationComponent } from './components/license-creation/license-creation.component';
import { LicenseDashboardComponent } from './components/license-dashboard/license-dashboard.component';
import { LicenseApprovalComponent } from './components/license-approval/license-approval.component';
import { ReqViewComponent } from './components/req-view/req-view.component';
import { LicenseViewComponent } from './components/license-view/license-view.component';
import { StrategyandvisionComponent } from './components/strategyandvision/strategyandvision.component';
import { GetstartedComponent } from './components/getstarted/getstarted.component';
import { NatureandeducateComponent } from './components/natureandeducate/natureandeducate.component';
import { NgxMdModule } from 'ngx-md';

export const protectedResourceMap: [string, string[]][] = [
  ['https://graph.microsoft.com/v1.0/me', ['user.read', 'openid', "profile"]]
];

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

function MSALConfigFactory(): Configuration {
  return {
    auth: {
      clientId: environment.APP_CLIENT_ID,
        // -- dev --
        authority: "https://login.microsoftonline.com/eb36d020-2c35-44de-bc7a-554cc979b26d",

        // -- Prod --
        //authority: "https://login.microsoftonline.com/548d26ab-8caa-49e1-97c2-a1b1a06cc39c",

        validateAuthority: true,
        // redirectUri: "http://localhost:4200",
        // postLogoutRedirectUri: "http://localhost:4200",
        // redirectUri: "http://localhost:8100",
        // postLogoutRedirectUri: "http://localhost:8100",
        // redirectUri:"https://powercoe-portal-test.azureedge.net",
        // postLogoutRedirectUri: "https://powercoe-portal-test.azureedge.net",
        // redirectUri: "https://ko-coe-powerapp.azureedge.net",
        // postLogoutRedirectUri: "https://ko-coe-powerapp.azureedge.net",
        redirectUri:"https://powercoe-test.coca-cola.com/",
        postLogoutRedirectUri:"https://powercoe-test.coca-cola.com/",
        navigateToLoginRequestUrl: true,
    },
    cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
  };
}

function MSALAngularConfigFactory(): MsalAngularConfiguration {
  return {
    //popUp: !isIE,
    consentScopes: [
      "user.read",
      "openid",
      "profile",
      environment.APP_LOGIN_CONFIG
    ],
    unprotectedResources: ["https://www.microsoft.com/en-us/"],
    protectedResourceMap,
    extraQueryParameters: {}
  };
}

@NgModule({
  declarations: [
    AppComponent,
    // HeaderComponent,
    FilterPipe,
    SearchComponent,
    FooterComponent,
    ArticlesComponent,
    CarsoalComponent,
    ServicesComponent,
    DocumentationComponent,
    HomeComponent,
    TrainingComponent,
    AboutusComponent,
    ReqEnvironmentComponent,
    ReqCreationComponent,
    LoginComponent,
    ReqDashboardComponent,
    ReqApprovalComponent,
    ArticleDetailsComponent,
    LicenseCreationComponent,
    LicenseDashboardComponent,
    LicenseApprovalComponent,
    ReqViewComponent,
    LicenseViewComponent,
    StrategyandvisionComponent,
    GetstartedComponent,
    NatureandeducateComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    FlexLayoutModule,
    MaterialModule,
    MatCarouselModule.forRoot(),
    CarouselModule.forRoot(),
    NgxSpinnerModule,
    MsalModule,
    HttpClientModule,
    NgxPermissionsModule.forRoot(),  
    ReactiveFormsModule,
    LazyLoadImageModule,
    NgxMdModule.forRoot(),
  
  ],
  providers: [
    MsalService,
    CoeAuthService,
    CoeAuthGuard,    
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CoeAuthInterceptor,
      multi: true
    },
    {
      provide: MSAL_CONFIG,
      useFactory: MSALConfigFactory
    },
    {
      provide: MSAL_CONFIG_ANGULAR,
      useFactory: MSALAngularConfigFactory
    },   
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
