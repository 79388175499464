<section class="section-padding">
    <div class="container">
        <div class="row">
            <div class="col">
                <p class="wow animate__animated animate__zoomIn animate__delay-0.5s">
                    We want to ensure that the everybody (makers, champions, developers, and administrators) have the
                    proper knowledge, training and tools to build, manage and support their apps on the Power Platform.
                </p>
                <p class="wow animate__animated animate__zoomIn animate__delay-0.5s">We are focused on the following key areas to nurture and educate Coca-Cola associates:</p>


                <ul class="ml-5">
                    <li class="wow animate__animated animate__zoomIn animate__delay-0.5s"><p><strong>Identifying Champions</strong> within the company. Champions will evangelize and help
                        train their teams on the new ways of working. They build awareness, understanding and engagement
                        throughout the community</p>
                        <ul class="ml-4">
                            <li>Create the enthusiasm that grows adoption of improved ways of working, and evangelize
                                the Power Platform and its value across teams</li>
                            <li>Build a circle of influence amongst their teams, and help people in their circle
                                understand what is in it for them</li>
                            <li>Bring the new ways of working to life across teams</li>
                            <li>Identify business challenges and possible solutions</li>
                            <li>Provide feedback to the POWERCOE team and executive sponsors </li>
                        </ul>
                    </li>
                    <li class="wow animate__animated animate__zoomIn animate__delay-0.5s">
                        <p><strong>Provide Self-Paced Online Training </strong>– Self-paced online training resources is available directly
                        from Microsoft.</p>
                        <ul>
                            <li><a href="https://docs.microsoft.com/learn/powerplatform/">Power Platform Microsoft Learn content</a></li>
                            <li><a href="https://docs.microsoft.com/learn/certifications/power-platform-fundamentals">Power Platform Fundamentals (Certification)</a></li>
                        </ul>
                    </li>
                    <li class="wow animate__animated animate__zoomIn animate__delay-0.5s">
                        <p><strong> Provide App in a Day Classes.</strong> A facilitated or self-guided hands-on training option which
                        material runs people through the core features and capability of the technology included in the
                        Power Platform, and above all, can be used by everyone in the organization in every single role
                        to get them thinking about how technology can make them more successful.</p>
                    </li>
                    <li class="wow animate__animated animate__zoomIn animate__delay-0.5s">
                        <p><strong>Share Success Stories and Case Studies.</strong> Author and publish articles to share experiences and
                        best practices for others to learn from.</p>
                    </li>
                    <li class="wow animate__animated animate__zoomIn animate__delay-0.5s">
                        <p> <strong>Right amount of Governance.</strong> Share what environments are available, how to request access to
                        existing environments or request new environments. Ensure makers know the boundaries of
                        environments with regards to licensing, security for environment and connectors. Share what
                        connectors are available in which environments, how to request additional connectors or a new
                        environment for a specific project. Define what a maker is responsible for, for example keeping
                        the
                        tenant tidy by deleting unused resources, ensuring apps are only shared with required end users,
                        supporting their apps in the Default environment or engaging with the POWERCOE team before
                        implementing a business-important or mission-critical app to plan appropriate support. Ensure
                        makers are educated about processes in place for business-important or mission-critical app,
                        such as engaging formal support from IT.</p>
                    </li>
                    <li class="wow animate__animated animate__zoomIn animate__delay-0.5s">
                        <p><strong>Forum software.</strong> A media to ask questions and find answers from the most common to the most
                        complex topics and scenarios makers and developers will encounter when building apps. We’re in
                        the process of implementing the open-source software Discourse to provide forum capability.
                        There’s a lot of disadvantage of a forum software versus Teams or Yammer because it’s build
                        specifically to facilitate discussions. It is able to categorize topics, reward people who ask
                        the most questions or provide the most or helpful answers by gamifying the experience with
                        badges and reputation points.</p>
                    </li>
                    <li class="wow animate__animated animate__zoomIn animate__delay-0.5s">
                        <p> <strong>Component library.</strong> Share re-usable components and templates as well as brand guidelines or
                        naming conventions you want your makers to follow. This allows ensure both consistency across
                        apps as well as enable makers to focus on the business logic in their app rather than on common
                        components.</p>
                        <p>The component library will include:</p>
                        <ul>
                            <li>Header</li>
                            <li>Navigation</li>
                            <li>Footer</li>
                            <li>Success Screen</li>
                            <li>Home Screen</li>
                            <li>Search Box</li>
                            <li>Pre-loader</li>

                        </ul>
                    </li>
                    <li class="wow animate__animated animate__zoomIn animate__delay-0.5s">
                        <p><strong>POWERCOE Yammer Group.</strong> Share recent internal or external news and highlights around the
                        Microsoft Power Platform.</p>
                    </li>
                    <li class="wow animate__animated animate__zoomIn animate__delay-0.5s">
                        <p> <strong>POWERCOE Stream Channel.</strong> Videos and talks to explain simple topics like to how to get started
                        with creating apps or complex subjects like environment strategy, etc.</p>
                    </li>
                    <li class="wow animate__animated animate__zoomIn animate__delay-0.5s">
                        <p> <strong>POWERCOE Portal.</strong> The portal is one stop shop for anybody who is interested to learn and get
                        started with everything related to the Microsoft Power Platform within Coca-Cola.</p>
                    </li>

                </ul>

            </div>
        </div>
    </div>

</section>