import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import {TooltipPosition} from '@angular/material/tooltip';
import { Component, OnInit, ViewChild, Self, Optional } from '@angular/core';
import { ApibundleService } from '../../services/apibundle.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { BroadcastService, MsalService } from '@azure/msal-angular';
import { NgxPermissionsService } from 'ngx-permissions';
import { Observable } from 'rxjs';
import { RequestDetailRequest } from '../../interfaces/requestDetailRequest.model';
import { CommonService } from '../../services/common.service';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { startWith, map } from 'rxjs/operators';
import { EnvName } from '../../interfaces/envName.model';
import { Location } from '@angular/common';

@Component({
  selector: 'app-req-creation',
  templateUrl: './req-creation.component.html',
  styleUrls: ['./req-creation.component.css']
})
export class ReqCreationComponent implements OnInit {
  // service:string = '';
  // noService:boolean = false;
  // constructor(
  //   private router: Router,
  //   private route: ActivatedRoute,
  // ) { 
  //   this.service = this.route.snapshot.paramMap.get('service');
  //   //const routereq = ['environment','license', 'developerenvironment'];
  //   //this.noService = routereq.includes(this.service) ? false : true;
  // }

  
  ngForm: FormGroup;
  loading: any;
  reqFromUrl: string = "";
  public title = "";
  public disabled;
  public btnTxt: string = "";
  norecord: boolean = false;
  approvedList;
  requestDetail = new RequestDetailRequest();
  err: any;
   /*--------  FORM CONTROL - NEED TO CHANGE IN FUTURE-------- */
  purpose = new FormControl('', [Validators.required]);
  busUnit = new FormControl('', [Validators.required]);
  envType = new FormControl('', [Validators.required]);
  envLand = new FormControl('', [Validators.required]);
  envRegion = new FormControl('', [Validators.required]);
  envCurrency = new FormControl('', [Validators.required]);
  reqCds = new FormControl('', [Validators.required]);
  connectors = new FormControl('', [Validators.required]);
  envAdmin = new FormControl('', [Validators.required]);
  highRestInfo = new FormControl('', [Validators.required]);
  ippPolicyStmt = new FormControl('', [Validators.required]);
  envName = new FormControl('', [Validators.required]);
   /*--------  END OF FORM CONTROL -------- */

  businessUnits: any = [];
  envTypeList: string[];
  envRegionList: any = [];
  envLandscapeList: any = [];
  connectorsList: any = [];
  envAdminList: any = [];
  evnCurrencyList: any = [];
  isAdminSelected: boolean = false;
  isSubmitted: boolean = false;
  filteredOptions: Observable<string[]>;
  isConnectorLoad: boolean = false;
  isEnvLandEnable: boolean = true;
  isIppPolicytSelectedYes: boolean = false;
  selectedAdminItems: any = [];
  selectedConnectItems: any = [];
  separatorKeysCodes: number[] = [ENTER, COMMA];
  @ViewChild('adminInput', {static: false}) adminInput;
  @ViewChild('connectInput', {static: false}) connectInput;
  filteredConnectors: any = [];
  envNameObj = new EnvName();
  spinnerName = "reqCreation";
  spinner: boolean = false;

  constructor(
    private apibundleService: ApibundleService,
    private permissionsService: NgxPermissionsService,
    private router: Router,
    private route: ActivatedRoute,
    private authService: MsalService,
    private broadcastService: BroadcastService,
    private commonService: CommonService,
    public location: Location
  ) {
     
    
  }

  ngOnInit() {
    this.initializeValues();
  }
  initializeValues() {
    this.envLandscapeList = ['Not Applicable'];
    //this.evnCurrencyList = ['USD ($)', 'EUR (€)', 'INR (₹)'];
    this.requestDetail = new RequestDetailRequest();
    this.getConnectors();
  }
  /*--------  END OF FUNCTION -------- */

  /*--------  FUNCTION IS CALLED WHEN THE FORM IS SUBMITTED. CHECK VALIDATION. NAVIGATE TO ENVIRONMENT REQUEST PAGE -------- */
  async processForm(formData) {
    if (formData.submitted)
        this.isSubmitted = true;
      this.requestDetail.RowKey = 'NAN';
      this.requestDetail.Environmentid = 'NAN';
      this.requestDetail.Username = this.authService.getAccount().userName;
      this.requestDetail.Connectors = this.selectedConnectItems;
      this.requestDetail.Admin = this.selectedAdminItems;
    if (this.validate() && this.selectedAdminItems.length > 0 && this.selectedConnectItems.length > 0 && this.selectedAdminItems.length <= 3) {
      this.requestDetail.EnvironmentName = this.envNameObj.envLand+'-'+this.envNameObj.busUnit+'-'+this.envNameObj.region+'-'+this.requestDetail.EnvironmentName;
      this.requestDetail.PartitionKey = this.requestDetail.EnvironmentName;
      this.requestDetail.RowKey = '';
      this.requestDetail.Environmentid = '';
      //this.requestDetail.Region = this.requestDetail.Region.replace(/ /g, '')
      this.commonService.setRequestData(this.requestDetail);
      this.router.navigate(['/services/environment/requestenvironment']);
    } 
  }
  /*--------  END OF FUNCTION -------- */

  /*--------  FUNCTION WILL RETUN COMMON ERROR MESSAGE -------- */
  getErrorMessage() {
    return 'You must enter a value';
  }
  /*--------  END OF FUNCTION -------- */

  /*--------  FUNCTION TO CHECK ANY EMMPTY VALUES. RETURN BOOLEAN -------- */
  validate() {
    for (var key in this.requestDetail) {
      if (this.requestDetail[key] == null || this.requestDetail[key] == '' || this.requestDetail[key].length == 0) {
        return false;
      }
    }
    return true;
  }
  /*--------  END OF FUNCTION -------- */

  /*--------  FUNCTION TO GET THE CONNECTORS -------- */
  async getConnectors() { 
    this.apibundleService.showSpinner('reqCreation');  
    this.spinner = true;
    this.apibundleService.getConnectors().subscribe(
      res => {
        this.connectorsList = res;
        this.filteredConnectors = res;
        this.getBusinessUnits();
        this.getRegions();
        this.getEnvTypes();
        this.getCurrencies(); 
        this.apibundleService.hideSpinner('reqCreation');   
        this.spinner = false;
      },
      err => {
        this.err = err;
        console.log(err);
        this.apibundleService.hideSpinner('reqCreation');   
        this.spinner = false;
      });
  }
  /*--------  END OF FUNCTION -------- */

  /*--------  FUNCTION TO GET THE BUSINESS UNITS -------- */
  async getBusinessUnits() {
    this.apibundleService.getBusinessUnits().subscribe(
      res => {
        this.businessUnits = res;
      },
      err => {
        this.err = err;
        console.log(err);
      });
  }
  /*--------  END OF FUNCTION -------- */

  /*--------  FUNCTION TO GET THE ENVIRONMENT TYPES -------- */
  async getEnvTypes() {
    this.apibundleService.getEnvTypes().subscribe(
      res => {
        this.envTypeList = res;
      },
      err => {
        this.err = err;
        console.log(err);
      });
  }
  /*--------  END OF FUNCTION -------- */

  /*--------  FUNCTION TO GET THE REGIONS -------- */
  async getRegions() {
    this.apibundleService.getRegions().subscribe(
      res => {
        this.envRegionList = res;
      },
      err => {
        this.err = err;
        console.log(err);
      });
  }
  /*--------  END OF FUNCTION -------- */

  /*--------  FUNCTION TO GET ADMIL LIST BY SEARCH TEXT -------- */
  async getEnvAdmins(event) {
    this.isAdminSelected = false;
    if (event.target.value.length > 2) {
    this.isConnectorLoad = true;
      this.envAdminList = [];
      var searchData = event.target.value;
      this.apibundleService.getEnvAdmins(searchData).subscribe(
        res => {
          this.envAdminList = res;
          this.isConnectorLoad = false;
        },
        err => {
          this.err = err;
          console.log(err);
          this.isConnectorLoad = false;
        });
    }
  }
  /*--------  END OF FUNCTION -------- */

  /*--------  FUNCTION TO GET ENVIRONMENT LANDSCAPE BASED ON ENVIRONMENT TYPE ID-------- */
  setEnvLandscape(envType) {
    this.isEnvLandEnable = true;
    this.apibundleService.getEnvLandscapesByEnvId(envType.rowKey).subscribe(
      res => {
        this.envLandscapeList = res;
        if (this.envLandscapeList.length > 0) {
          this.requestDetail.EnvironmentLandscape = '';
          this.isEnvLandEnable = false;
        } else {
          this.requestDetail.EnvironmentLandscape = 'Not Applicable';
          this.envLandscapeList = [{'environmentlandscape':'Not Applicable'}];
          this.isEnvLandEnable = true;
        }
      },
      err => {
        this.err = err;
        console.log(err);
      });
  }
  /*--------  END OF FUNCTION -------- */

   /*--------  FUNCTION TO CHECK WHETHER THE IPP POLICY STATEMENT IS SELECTED OR NOT -------- */
   checkAcceptSelected(event) {
    this.isIppPolicytSelectedYes = true;
    if(event.value == 'Yes') {
      this.isIppPolicytSelectedYes = false;
    } else {
      this.isIppPolicytSelectedYes = true;
    }
  }
  /*--------  END OF FUNCTION -------- */

  /*--------  FUNCTION TO ADD SELECTED ADMIN -------- */
  addSelectAdmin(event) {
    let envAdmin = event.option.value;
    if (envAdmin) {
    let index = -1;
    index = this.selectedAdminItems.findIndex(value => value.userEmail === envAdmin.userEmail);
      if(index < 0) {      
        this.selectedAdminItems.push(envAdmin); 
        this.adminInput.nativeElement.value = '';
      }
    } 
  }
  /*--------  END OF FUNCTION -------- */

/*--------  FUNCTION TO REMOVE SELECTED ADMIN -------- */
  removeAdmin(envAdmin) {
    let i = -1;
    i = this.selectedAdminItems.findIndex(value => value.userEmail === envAdmin.userEmail );
    if(i > -1)
      this.selectedAdminItems.splice(i, 1);
  }
  /*--------  END OF FUNCTION -------- */

  /*--------  FUNCTION TO ADD SELECTED CONNECTOR -------- */
  addSelectConnector(event) {
    let connectorName = event.option.value;
    if (connectorName) {
    let index = -1;
    index = this.selectedConnectItems.findIndex(value => value === connectorName);
      if(index < 0) {      
        this.selectedConnectItems.push(connectorName); 
        this.connectInput.nativeElement.value = '';
      }
    } 
  }
  /*--------  END OF FUNCTION -------- */

  /*--------  FUNCTION TO REMOVE SELECTED CONNECTOR -------- */
  removeConnector(connectorName) {
    let i = -1;
    i = this.selectedConnectItems.findIndex(value => value === connectorName );
    if(i > -1)
      this.selectedConnectItems.splice(i, 1);
  }
  /*--------  END OF FUNCTION -------- */

  /*--------  FUNCTION TO FILTER CONNECTORS -------- */
  filterConnectors(event) {
    const filterValue = event.target.value.toLowerCase();
      this.filteredConnectors = this.connectorsList.filter(con => con.connectorName.toLowerCase().includes(filterValue));
  }
  /*--------  END OF FUNCTION -------- */

  /*--------  FUNCTION TO GET THE CURRENCIES -------- */
  async getCurrencies() {
    this.apibundleService.getCurrencies().subscribe(
      res => {
        this.evnCurrencyList = res;
      },
      err => {
        this.err = err;
        console.log(err);
      });
  }
  /*--------  END OF FUNCTION -------- */

  formEnvNameLand(envLandAbbr) {
    this.envNameObj.envLand = envLandAbbr;
  }

  formEnvNameBu(busAbbr) {
    this.envNameObj.busUnit = busAbbr;
  }

  formEnvNameReg(regAbbr) {
    this.envNameObj.region = regAbbr;
  }

}
