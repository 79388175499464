<header>
  <section class="{{ isShow ? 'sticky main-header' : 'main-header'}}">
    <div class="container p-0">
      <mat-toolbar class="p-0">
        <mat-toolbar-row class="p-0">
          <button mat-icon-button *ngIf="device" class=" mr-2">
            <mat-icon (click)="sidenav.toggle()">menu</mat-icon>
          </button>
          <img class="logo" src="../assets/img/{{logoname}}" (click)="go('');sidenav.close()"
            style="cursor: pointer;" />
          <span class="spacer"></span>
          <div *ngIf="!device" id="nav" class="right affix-top">
            <a *ngFor="let item of navigationMenu" mat-button [routerLink]="[item.route]"
              [routerLinkActive]="['is-active']">
              {{item.title | uppercase }}
            </a>
            <a href="https://koforums.eastus.cloudapp.azure.com/" mat-button target="_blank" [routerLinkActive]="['is-active']">
            FORUMS
          </a>
          </div>
          <span class="spacer"></span>
          <!-- <app-search class="pl-2 pr-2"></app-search> -->
          <button mat-icon-button *ngIf="isLoggedIn" [matMenuTriggerFor]="menu" style="width: 42px;height: 42px;">
            <img *ngIf="base64String" style="border-radius: 50%;width: 42px;height: 42px;background:#ddd;"
              src="{{base64String}}" alt="profileImage" />
            <img *ngIf="!base64String" style="border-radius: 50%;width: 42px;height: 42px;background:#ddd;"
              src="../assets/img/avator.png" alt="profileImage" />
          </button>
          <mat-menu #menu="matMenu" class="animate__animated animate__zoomIn animate__delay-0.3s">
            <mat-card class="profile-card border-0 m-0 mt-2">
              <mat-card-header>
                <div mat-card-avatar class="profile-header-avator">
                  <img *ngIf="base64String" style="background:#ddd;border-radius: 50%;" src="{{base64String}}"
                    alt="profileImage" />
                  <img *ngIf="!base64String" style="background:#ddd;border-radius: 50%;" src="../assets/img/avator.png"
                    alt="profileImage" />
                </div>
                <mat-card-title class="mb-0">{{user}}</mat-card-title>
                <mat-card-subtitle>{{email}}</mat-card-subtitle>
              </mat-card-header>
              <mat-card-content>
                <div class="text-left m-2">
                  <button mat-menu-item routerLink="services/myrequests">
                    <mat-icon>shopping_cart</mat-icon>
                    <span>My Request</span>
                  </button>
                  <button mat-menu-item *ngIf="isLoggedIn" (click)="logout()">
                    <mat-icon>logout</mat-icon>
                    <span>Logout</span>
                  </button>
                </div>
              </mat-card-content>
            </mat-card>
          </mat-menu>
        </mat-toolbar-row>
      </mat-toolbar>
    </div>
  </section>
  <section>
    <mat-sidenav #sidenav mode="over">
      <mat-nav-list *ngIf="device">
        <a *ngFor="let item of navigationMenu" mat-list-item (click)="sidenav.close()" [routerLink]="[item.route]"
          [routerLinkActive]="['is-active']">
          <mat-icon matListIcon> {{ item.icon }} </mat-icon>
          {{item.title | uppercase}}
        </a>
        <a href="https://koforums.eastus.cloudapp.azure.com/" mat-list-item (click)="sidenav.close()" target="_blank" [routerLinkActive]="['is-active']">
          <mat-icon matListIcon> person </mat-icon>
          FORUMS
        </a>
        <mat-divider></mat-divider>
      </mat-nav-list>
    </mat-sidenav>
  </section>
  <section>
    <div style="background-image:url('../assets/img/bg/{{bannerImage}}');" class="{{ device ? 'banner-mobile' :'banner'}} {{bannerStyle}}">
      <div class="banner-text" *ngIf="bannerText === true && bannerFor === 'home'">
        <h1>The power to solve your</h1>
        <h1> business problems is within you</h1>
        <h4 style="color: #cecece;font-weight: lighter;">Build no-code to low-code apps with the Microsoft Power Platform</h4>
        <button mat-raised-button color="warn" (click)="go('getstarted');">Get Started</button>
      </div>
      <div class="{{ device ?'banner-text-highlight-mobile':'banner-text-highlight'}}" *ngIf="bannerText === true && bannerFor === 'services'">
        <h1 class="mt-4">SERVICES</h1>
      </div>
      <div class="{{ device ?'banner-text-highlight-mobile':'banner-text-highlight'}}" *ngIf="bannerText === true && bannerFor === 'training'">
        <h1 class="mt-4">TRAINING</h1>
      </div>
      <div class="{{ device ?'banner-text-highlight-mobile':'banner-text-highlight'}}" *ngIf="bannerText === true && bannerFor === 'documentation'">
        <h1 class="mt-4">DOCUMENTATION</h1>
      </div>
      <div class="{{ device ?'banner-text-highlight-mobile':'banner-text-highlight'}}" *ngIf="bannerText === true && bannerFor === 'articles'" style="background: #21252929 !important;">
        <h1 class="mt-4">ARTICLES</h1>
      </div>
      <div class="{{ device ?'banner-text-highlight-mobile':'banner-text-highlight'}}" *ngIf="bannerText === true && bannerFor === 'aboutus'">
        <h1 class="mt-4">ABOUT US</h1>
      </div>
      <div class="{{ device ?'banner-text-highlight-mobile':'banner-text-highlight'}}" *ngIf="bannerText === true && bannerFor === 'strategyandvision'">
        <h1 class="mt-4">STRATEGY AND VISION</h1>
      </div>
      <div class="{{ device ?'banner-text-highlight-mobile':'banner-text-highlight'}}" *ngIf="bannerText === true && bannerFor === 'getstarted'">
        <h1 class="mt-4">GET STARTED</h1>
      </div>
      <div class="{{ device ?'banner-text-highlight-mobile':'banner-text-highlight'}}" *ngIf="bannerText === true && bannerFor === 'natureandeducate'" style="background: #21252929 !important;">
        <h1 class="mt-4">NURTURE AND EDUCATE</h1>
      </div>
    </div>
  </section>
</header>
<main>
  <mat-sidenav-container>
    <mat-sidenav-content class="content">
      <!-- <div class="container"> -->
      <router-outlet></router-outlet>
    <!-- </div> -->
    </mat-sidenav-content>
  </mat-sidenav-container>
</main>
<footer class="footer">
  <section>
    <app-footer [deviceXs]="deviceXs"></app-footer>
    <button mat-icon-button *ngIf="isShow" class="dmtop" (click)="gotoTop()">
      <mat-icon class="scroll-icon" style="color:white">keyboard_arrow_up</mat-icon>
    </button>   
  </section>
</footer>