<section class="section-padding">
    <div class="container">
        <div class="row">
            <div class="col">
                <article>
                    <div class="row">
                        <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                            <div id="power-apps" (mouseover)="mouseOver('img1', 'power-apps')" class="wow animate__animated animate__zoomIn animate__delay-0.5s">
                                <div class="text-center" style="cursor:pointer" routerLink="/documentation/powerapps"><img src="../../../assets/img/pages/PowerApps_round_icon.png"
                                        alt="Power Apps" /></div>
                                <mat-card-title style="cursor:pointer" routerLink="/documentation/powerapps" class="text-center mt-2">Power Apps</mat-card-title>
                                <p class="mt-2"><strong>Power Apps </strong>is a suite of apps, services, connectors and data platform that provides a rapid application
                                    development environment to build custom apps for your business needs. </p>
                                <a routerLink="/documentation/powerapps" style="color:red">Read More...</a>                           

                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                            <div id="power-automate" (mouseover)="mouseOver('img2', 'power-automate')" class="wow animate__animated animate__zoomIn animate__delay-0.5s">
                                <div style="cursor:pointer" class="text-center" routerLink="/documentation/powerautomate"><img
                                        src="../../../assets/img/pages/PowerAutomate_round_icon.png"
                                        alt="Power Automate" /></div>
                                <mat-card-title style="cursor:pointer" routerLink="/documentation/powerautomate" class="text-center mt-2">Power Automate</mat-card-title>
                                <p class="mt-2">What can you expect from <strong>Power Automate? </strong>Here are a few examples of what you can do:
                                    Automate business processes, Send automatic reminders for past due tasks,Move business data between systems on a schedule
                                       
                                     </p>
                                <a routerLink="/documentation/powerautomate" style="color:red">Read More...</a>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                            <div id="power-platform" (mouseover)="mouseOver('img3', 'power-platform')" class="wow animate__animated animate__zoomIn animate__delay-0.5s">
                                <div class="text-center" routerLink="/documentation/powervirtualagent"><img src="../../../assets/img/pages/PVA_round_icon.png"
                                        alt="Power virtual Agent" /></div>
                                <mat-card-title style="cursor:pointer" class="text-center mt-2" routerLink="/documentation/powervirtualagent">Power virtual Agent</mat-card-title>
                                <p class="mt-2"><strong>Power Virtual Agents</strong> empowers teams to easily create powerful bots using a guided, no-code graphical
                                    interface without the need for data scientists or developers. </p>
                                <a routerLink="/documentation/powervirtualagent" style="color:red">Read More...</a>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                            <div id="power-bi" (mouseover)="mouseOver('img4', 'power-bi')" class="wow animate__animated animate__zoomIn animate__delay-0.5s">
                                <div class="text-center" style="cursor:pointer" routerLink="/documentation/powerbi"><img src="../../../assets/img/pages/PBI_round_icon.png"
                                        alt="Power Bi" /></div>
                                <mat-card-title routerLink="/documentation/powerbi" style="cursor:pointer" class="text-center mt-2">Power Bi</mat-card-title>
                                <p class="mt-2"><strong>Power BI</strong> is a collection of software services, apps, and connectors that work together to
                                    turn your unrelated sources of data into coherent, visually immersive, and interactive insights. </p>
                                <a routerLink="/documentation/powerbi" style="color:red">Read More...</a>
                            </div>
                        </div>
                    </div>
                   
                    <div class="power-images mt-4 mb-4">
                        <img id="img1" src="../../../assets/img/pages/powerapps.jpg" alt="powerapps" /> 
                        <img id="img2" src="../../../assets/img/pages/powerautomate.jpg" alt="powerautomate" />
                        <img id="img3" src="../../../assets/img/pages/powervirtualagents.jpg"
                            alt="powervirtualagents" />
                        <img id="img4" src="../../../assets/img/pages/powerbi.jpg" alt="powerbi" />
                    </div>
                </article>

            </div>
        </div>
    </div>
</section>