import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-getstarted',
  templateUrl: './getstarted.component.html',
  styleUrls: ['./getstarted.component.css']
})
export class GetstartedComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {   
    document.getElementById('img1').style.display = "block";
    let element = document.getElementById('img1');
    element.classList.add("wow animate__animated animate__pulse animate__delay-0.5s");
  }

  mouseOver(id, divid) {   
    document.getElementById('img1').style.display = "none";
    document.getElementById('img2').style.display = "none";
    document.getElementById('img3').style.display = "none";
    document.getElementById('img4').style.display = "none";
    document.getElementById(id).style.display = "block";
    let element = document.getElementById(divid);
    element.classList.add("wow animate__animated animate__pulse animate__delay-0.5s");
  }
  
  // mouseOut(id, divid) {
  //   document.getElementById(id).style.display = "none";
  //   let element = document.getElementById(divid);
  //   element.classList.remove("animate__animated animate__pulse animate__delay-0.5s");
  // }

}
