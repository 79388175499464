import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { observable, Subscription } from 'rxjs';
import { async } from '@angular/core/testing';
import { ApibundleService } from './apibundle.service';
import { environment } from '../../environments/environment';
import { BroadcastService, MsalService } from '@azure/msal-angular';


declare var window;

@Injectable({
  providedIn: 'root'
})
export class CoeAuthService {
  constructor(private broadcastService: BroadcastService, private apiBundleService: ApibundleService,private msal: MsalService) { }

  initializeProfile(): Promise<boolean> {  
    return new Promise((resolve, reject) => {      
      this.broadcastService.subscribe('msal:loginSuccess', (response) => {
        window.localStorage.setItem('COE_OAUTH_ID_TOKEN', "Bearer " + response.idToken.rawIdToken);
        let user = response.account.name.split(" ");
        window.localStorage.setItem('COE_USER_NAME', user[0]);
        window.localStorage.setItem('COE_USER_EMAIL', response.account.userName);
        window.localStorage.setItem('COE_USER_LOGIN', 'success');
          //console.log("Returning from service");
          return resolve(true);
      })
      this.broadcastService.subscribe('msal:loginFailure', (response) => {
        reject(response);
      })     
    });
  }

  redirectToLogin(){
    this.msal.loginPopup().then(function (loginResponse) {
      //login success
      let idToken = loginResponse.idToken;
      window.location.href="/"
      return false
  }).catch(function (error) {
      //login failure
      console.log(error);
      window.open('/','_self');
      return false
  });
  }
  
}