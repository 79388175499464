<section style="background: #dddddd61;min-height:50px;">
  <div class="container">
    <div class="row">
      <div class="col">
      <nav aria-label="breadcrumb">
      <ol class="breadcrumb m-0" style="background-color: transparent !important;margin-left:0px;">
        <li class="breadcrumb-item"><a href="#">Documentation</a></li>
        <li *ngFor="let node of breadCrumnArray" class="breadcrumb-item">
          <a *ngIf="node.parentId == -1">{{node.name}}</a>
          <a style="cursor:pointer"  *ngIf="node.parentId > -1" (click)="getDoc(node)">{{node.name}}</a>
        </li>
        <!-- <li class="breadcrumb-item active" aria-current="page"></li> -->
      </ol>
    </nav>
    </div>
    </div>
    </div>
</section>
<section class="section-padding">
  <div class="container">
    <div class="row">
      <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4 wow animate__animated animate__zoomIn animate__delay-0.5s">
        <div>
          <!-- <mat-toolbar class="search-block-docs">
            <mat-toolbar-row style="padding: 0 10px;">
              <button class="search-icon" mat-icon-button disabled>
                <mat-icon style="color: red;">search</mat-icon>
              </button>
              <input class="search-control" type="text" style="color:#000;" [(ngModel)]="searchDocs"
                placeholder="Search" #searchbardocs (keyup)="searchDocsMenu()">
              <button mat-button *ngIf="searchDocs" matSuffix mat-icon-button aria-label="Clear"
                (click)="searchDocs='';searchDocsMenu();">
                <mat-icon>close</mat-icon>
              </button>
            </mat-toolbar-row>
          </mat-toolbar> -->
          <form  class="search-block-docs">
            <mat-form-field appearance="outline">
              <input type="text"
              placeholder="Search"
              aria-label="Number"
              matInput
              [matAutocomplete]="auto" (keyup)="searchDocsMenu($event)">
              <mat-icon matPrefix>search</mat-icon>
              <mat-autocomplete #auto="matAutocomplete">
                  <mat-option *ngFor="let element of searchArrayList" (onSelectionChange)="getDoc(element)"
                     >
                      {{element.name}}
                  </mat-option>
              </mat-autocomplete>
          </mat-form-field>
          </form>

          <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="tree-item">
            <mat-tree-node style="cursor:pointer;" *matTreeNodeDef="let node" matTreeNodePadding
              (click)="getDoc(node)">
              <button mat-icon-button disabled></button>
              {{node.name}}
            </mat-tree-node>
            <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding>
              <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.name">
                <mat-icon class="mat-icon-rtl-mirror">
                  {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                </mat-icon>
              </button>
              {{node.name}}
            </mat-tree-node>
          </mat-tree>
          
          <!-- <ngx-md class="menuClass" [data]="docMenu"> 
          </ngx-md>-->
        </div>
      </div>

      <div
        class="col-xs-12 col-sm-8 col-md-8 col-lg-8 wow animate__animated animate__zoomIn animate__delay-0.5s documentation">
        <div>
          <article>
            <ngx-md [data]="daocData">
            </ngx-md>
          </article>
        </div>
      </div>
    </div>
  </div>
</section>