<section class="section-padding">
  <div class="container">
    <div class="row" *ngIf="spinner">
      <div class="col">
        <div class="spinnerDivHeight">
          <ngx-spinner [name]="spinnerName" bdColor="transparent" size="default" color="red" type="timer"
            [fullScreen]="false">
            <p style="color: #000;margin-top:50px"> Please wait ... </p>
          </ngx-spinner>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col animate__animated animate__zoomIn animate__delay-0.5s">
        <!-- <h1>{{post && post.title ? post.title.rendered : "..."}}</h1> -->
        <h1 *ngIf="post && post.title.rendered">{{ post.title.rendered }}</h1>
        <div class="article-details" [innerHtml]="post.content.rendered" *ngIf="post && post.content"></div>
        <p *ngIf="post && post.date">{{post.date | date:'longDate'}}</p>
      </div>
    </div>
    <div class="row mt-4" *ngIf="noRecords">
      <div class="col text-center mx-auto">
        <div class="error-message-template">
          No Records
        </div>
      </div>
    </div>
    <div class="row mt-4" *ngIf="error">
      <div class="col text-center mx-auto">
        <div class="error-message-template">
          {{errorMsg}}
        </div>
      </div>
    </div>
  </div>
</section>