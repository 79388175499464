import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ArticlesComponent } from './components/articles/articles.component';
import { ServicesComponent } from './components/services/services.component';
import { HomeComponent } from './components/home/home.component';
import { DocumentationComponent } from './components/documentation/documentation.component';
import { TrainingComponent } from './components/training/training.component';
import { AboutusComponent } from './components/aboutus/aboutus.component';
import { ReqEnvironmentComponent } from './components/req-environment/req-environment.component';
import { ReqCreationComponent } from './components/req-creation/req-creation.component';
import { MsalGuard } from '@azure/msal-angular';
import { CoeAuthGuard } from './guards/coe-Auth.guard'
import { ReqDashboardComponent } from './components/req-dashboard/req-dashboard.component';
import { ReqApprovalComponent } from './components/req-approval/req-approval.component';
import { ArticleDetailsComponent } from './components/article-details/article-details.component';
import { LicenseCreationComponent } from './components/license-creation/license-creation.component';
import { LicenseDashboardComponent } from './components/license-dashboard/license-dashboard.component';
import { LicenseApprovalComponent } from './components/license-approval/license-approval.component';
import { ReqViewComponent } from './components/req-view/req-view.component'
import { LicenseViewComponent } from './components/license-view/license-view.component'
import { StrategyandvisionComponent } from './components/strategyandvision/strategyandvision.component';
import { GetstartedComponent } from './components/getstarted/getstarted.component';
import { NatureandeducateComponent } from './components/natureandeducate/natureandeducate.component';


const routes: Routes = [
    { path: '', redirectTo: 'home', pathMatch: 'full'}, 
    { path: 'home', component: HomeComponent, canActivate:[CoeAuthGuard]}, 
    { path: 'services', component: ServicesComponent, canActivate:[CoeAuthGuard] }, 
    { path: 'documentation', component: DocumentationComponent, canActivate:[CoeAuthGuard] },
    { path: 'documentation/:docs', component: DocumentationComponent, canActivate:[CoeAuthGuard] },
    { path: 'training', component: TrainingComponent, canActivate:[CoeAuthGuard]},
    { path: 'articles', component: ArticlesComponent, canActivate:[CoeAuthGuard]},
    { path: 'articles-details/:id', component: ArticleDetailsComponent, canActivate:[CoeAuthGuard]},
    { path: 'aboutus', component: AboutusComponent},
    { path: 'services/myrequests', component: ReqDashboardComponent, canActivate:[CoeAuthGuard]},
    { path: 'services/approverequest', component: ReqApprovalComponent, canActivate:[CoeAuthGuard]},
    { path: 'services/environment/createrequest', component: ReqCreationComponent, canActivate:[CoeAuthGuard]},
    { path: 'services/environment/view/:reqId', component: ReqViewComponent, canActivate:[CoeAuthGuard]},
    { path: 'services/environment/requestenvironment', component: ReqEnvironmentComponent, canActivate:[CoeAuthGuard]},
    { path: 'services/license/createrequest', component: LicenseCreationComponent, canActivate:[CoeAuthGuard]},
    { path: 'services/license/dashboard', component: LicenseDashboardComponent, canActivate:[CoeAuthGuard]},
    { path: 'services/license/approvelicense', component: LicenseApprovalComponent, canActivate:[CoeAuthGuard]},
    { path: 'services/license/view/:reqId', component: LicenseViewComponent, canActivate:[CoeAuthGuard]},
    { path: 'strategyandvision', component: StrategyandvisionComponent},
    { path: 'getstarted', component: GetstartedComponent},
    { path: 'natureandeducate', component: NatureandeducateComponent},    
    { path: '**', redirectTo: ''}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
