<section class="section-padding">
  <div class="container">
    <div class="row" *ngIf="spinner">
      <div class="col">
        <div class="spinnerDivHeight">
          <ngx-spinner [name]="spinnerName" bdColor="transparent" size="default" color="red" type="timer"
            [fullScreen]="false">
            <p style="color: #000;margin-top:50px"> Please wait ... </p>
          </ngx-spinner>
        </div>
      </div>
    </div>
    <div class="row">
      <div [ngClass]="cssClasses" *ngFor="let item of services;">
        <mat-card class="article-card wow animate__animated animate__zoomIn animate__delay-0.5s">
          <div mat-card-avatar class="m-auto mb-2 example-header-image" (click)="go(item.route);">
            <mat-icon aria-hidden="false" aria-label="icon">{{item.icon}}</mat-icon>
          </div>
          <mat-card-header class="services">
            <mat-card-title (click)="go(item.route);">{{item.title}}</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <hr>
            <p>
              The Shiba Inu is the smallest of the six original and distinct spitz breeds of dog from Japan.
              A small, agile dog that copes very well with mountainous terrain, the Shiba Inu was originally
              bred for hunting.
            </p>
            <button mat-stroked-button color="warn" (click)="go(item.route);">Read More...</button>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
</section>
<!-- <app-login></app-login> -->