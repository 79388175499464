// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  APP_TENANT_ID: 'eb36d020-2c35-44de-bc7a-554cc979b26d',
  APP_CLIENT_ID: '79028477-6a62-497f-a85e-68aff2e9f227',
  APP_DIRECTLINE_TOKEN_API: 'https://ko-da-directlineapi.azurewebsites.net/DirectLineApi/gettoken',
  API_ROOT_URL: 'https://globalengg-ko-appservc-coepowerapp.azurewebsites.net/api/',
  API_ROOT_APP_URL: ' https://globalengg-ko-appservc-coepowerapp.azurewebsites.net/api/checkapprover/',
  APP_LOGIN_CONFIG: 'api://79028477-6a62-497f-a85e-68aff2e9f227/COE',  
  //APP_DOMAIN_URL: 'https://kb-bot-ui-dev.azureedge.net',
  APP_DOMAIN_URL: 'http://localhost:8100'
};

// export const environment = {
//   production: true,
//   APP_TENANT_ID: '548d26ab-8caa-49e1-97c2-a1b1a06cc39c',
//   APP_CLIENT_ID: '6e193882-7040-430f-b22d-5ca004ed6c49',
//   API_ROOT_URL: 'http://globalengg-appservc-coepowerapp.azurewebsites.net/api/',
//   API_ROOT_APP_URL: 'http://globalengg-appservc-coepowerapp.azurewebsites.net/api/checkapprover/',
//   APP_LOGIN_CONFIG: 'api://6e193882-7040-430f-b22d-5ca004ed6c49/COE',  
//   APP_DOMAIN_URL: 'http://localhost:8100'
// };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
