<section class="section-padding">
    <div class="container">
        <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12  wow animate__animated animate__zoomIn animate__delay-0.5s">
                <p><strong>Lorem Ipsum</strong> is simply dummy text of the printing and typesetting industry. Lorem
                    Ipsum
                    has
                    been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley
                    of
                    type
                    and scrambled it to make a type specimen book. It has survived not only five centuries, but also the
                    leap
                    into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with
                    the
                    release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop
                    publishing
                    software like Aldus PageMaker including versions of Lorem Ipsum.</p>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                <h5>eLEARNING</h5>
                <hr>
                <mat-list>
                    <mat-card *ngFor="let message of messagesLern | appFilter: searchText:component" class="m-0 wow animate__animated animate__zoomIn animate__delay-0.5s">
                        <mat-card-header>
                            <div mat-card-avatar><img matListAvatar src="../../../assets/images/books/{{message.img}}"
                                    alt="Photo of a Shiba Inu"></div>
                            <mat-card-title>{{message.course}}</mat-card-title>
                            <mat-card-subtitle>{{message.subject}}</mat-card-subtitle>
                            <mat-card-subtitle>{{message.content}}</mat-card-subtitle>
                        </mat-card-header>
                    </mat-card>
                </mat-list>

            </div>
            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                <h5>INSTRUCTOR-led</h5>
                <hr>
                <mat-list>
                    <mat-card *ngFor="let message of messagesList | appFilter: searchText: component" class="m-0 wow animate__animated animate__zoomIn animate__delay-0.5s">
                        <mat-card-header>
                            <div mat-card-avatar><img matListAvatar src="../../../assets/images/books/training.png"
                                    alt="Photo of a Shiba Inu"></div>
                            <mat-card-title>{{message.subject}}</mat-card-title>
                            <mat-card-subtitle>{{message.date}}</mat-card-subtitle>
                            <mat-card-subtitle>{{message.duration}}</mat-card-subtitle>
                            <mat-card-subtitle>{{message.instructor}}</mat-card-subtitle>
                        </mat-card-header>
                    </mat-card>
                </mat-list>
            </div>
        </div>
    </div>
</section>