<section class="section-padding">
  <div class="container">
    <!-- <div class="row">
      <div class="col">
        <mat-chip-list class="example-chip" cdkDropList cdkDropListOrientation="horizontal"
          (cdkDropListDropped)="drop($event)">
          <mat-chip class="example-box" cdkDrag *ngFor="let vegetable of vegetables"
            (click)="loadArticles(vegetable.id)">
            {{vegetable.name}}
          </mat-chip>
        </mat-chip-list>
      </div>
    </div> -->
    <div class="row" *ngIf="spinner">
      <div class="col">
        <div class="spinnerDivHeight">
          <ngx-spinner [name]="spinnerName" bdColor="transparent" size="default" color="red" type="timer"
            [fullScreen]="false">
            <p style="color: #000;margin-top:50px"> Please wait ... </p>
          </ngx-spinner>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3" *ngFor="let post of posts | appFilter: searchText: component | slice:0:max">
        <mat-card class="example-card wow animate__animated animate__zoomIn animate__delay-0.5s">
          <div>
            <img *ngIf="post.better_featured_image" mat-card-image class="article-card-image"
              src="{{post.better_featured_image.source_url}}" alt="Photo of a Shiba Inu"
              routerLink="/articles-details/{{post.id}}">
            <img *ngIf="!post.better_featured_image" mat-card-image class="article-card-image"
              src="../../../assets/img/articles/articles.jpg" alt="Photo of a Shiba Inu"
              routerLink="/articles-details/{{post.id}}">
            <div mat-card-avatar class="profile-header-article-avator"></div>
          </div>
          <mat-card-content>
            <div class="card-body">
              <h4 class="card-title ellipsis"><a
                  routerLink="/articles-details/{{post.id}}">{{post.title.rendered ? post.title.rendered : "Loading ..."}}</a>
              </h4>
              <p>{{post.date | date:'longDate'}}</p>
              <div class="excerpt-content" [innerHtml]="post.excerpt.rendered" *ngIf="post && post.excerpt"></div>
              <button mat-stroked-button color="warn" routerLink="/articles-details/{{post.id}}">Read
                More...</button>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
    <div class="row m-5" *ngIf="!error">
      <div class="col text-center mx-auto">
        <button mat-raised-button color="warn" (click)="loadMore()" [disabled]="btnloadMoreDisable">Load More
          ...</button>
      </div>
    </div>
    <div class="row mt-4" *ngIf="noRecords">
      <div class="col text-center mx-auto">
        <div class="error-message-template">
          No Records
        </div>
      </div>
    </div>
    <div class="row mt-4" *ngIf="error">
      <div class="col text-center mx-auto">
        <div class="error-message-template">
          {{errorMsg}}
        </div>
      </div>
    </div>
  </div>
</section>