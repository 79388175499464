import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-strategyandvision',
  templateUrl: './strategyandvision.component.html',
  styleUrls: ['./strategyandvision.component.css']
})
export class StrategyandvisionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
