import { Component, OnInit } from '@angular/core';
import { MessageService } from '../../services/message.service';

@Component({
  selector: 'app-training',
  templateUrl: './training.component.html',
  styleUrls: ['./training.component.css']
})
export class TrainingComponent implements OnInit {
  searchText = '';
  component = '';

  constructor(
    private messageService: MessageService
  ) {
    this.messageService.getMessage().subscribe(message => {
      if (message == this.messageService.searchText) {
        this.searchText = message[0].searchText;
        this.component = message[0].component;   
      }
    });
   }

  ngOnInit(): void {
  }

  messagesLern = [
    {
      course: 'MicroSoft',
      subject: 'Technology company',
      content: 'Microsoft Corporation is an American multinational technology...',
      img:'mc.png'
    },
    {
      course: 'LinkedIn',
      subject: 'Social networking service',
      content: 'LinkedIn is an American business and employment-oriented online service...',
      img:'in.png'
    },
    {
      course: 'Google',
      subject: 'Technology company',
      content: 'Google LLC is an American multinational technology company...',
      img:'go.png'
    },
    {
      course: 'Basecamp',
      subject: 'Project Management',
      content: 'Trusted by millions, Basecamp puts everything you need to get work done in one place...',
      img:'bc.png'
    },
    {
      course: 'Adobe',
      subject: 'Computer software company',
      content: 'Adobe Inc., known course 1982 to 2018 as Adobe Systems Incorporated...',
      img:'ad.png'
    },
  ]
  messagesList = [
    {
      subject: 'Power Apps Component Framework deep-drive',
      date: '25/07/2020',
      duration: '4 hours',
      instructor: 'Lokeshwar vangala'
    },
    {
      subject: 'Power Apps Component Framework deep-drive',
      date: '25/07/2020',
      duration: '4 hours',
      instructor: 'Lokeshwar vangala'
    },
    {
      subject: 'Power Apps Component Framework deep-drive',
      date: '25/07/2020',
      duration: '4 hours',
      instructor: 'Lokeshwar vangala'
    },
    {
      subject: 'Power Apps Component Framework deep-drive',
      date: '25/07/2020',
      duration: '4 hours',
      instructor: 'Lokeshwar vangala'
    },
    {
      subject: 'Power Apps Component Framework deep-drive',
      date: '25/07/2020',
      duration: '4 hours',
      instructor: 'Lokeshwar vangala'
    },
  ]

}
