import { Component, OnInit, ViewChild } from '@angular/core';
import { ApibundleService } from '../../services/apibundle.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { BroadcastService, MsalService } from '@azure/msal-angular';
import { NgxPermissionsService } from 'ngx-permissions';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { RequestDetailRequest } from '../../interfaces/requestDetailRequest.model';
import { CommonService } from '../../services/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';

@Component({
  selector: 'app-req-environment',
  templateUrl: './req-environment.component.html',
  styleUrls: ['./req-environment.component.css']
})
export class ReqEnvironmentComponent implements OnInit {
  ngForm: FormGroup;
  loading: any;
  public title = "";
  public disabled;
  public btnTxt: string = "";
  public isMultiTurnChecked = false;
  norecord: boolean = false;
  requestDetail = new RequestDetailRequest();
  isAdminSelected: boolean = false;
  envList: any;
  displayedColumns = ['environment'];
  dataSource: any;
  err: any; 
  isFilterData: boolean = false;  
  @ViewChild(MatPaginator) paginators: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  spinner: boolean = false;
  spinnerName = "reqEnvironment";

  constructor(
    private apibundleService: ApibundleService,
    private router: Router,
    private broadcastService: BroadcastService,
    private commonService: CommonService,
    public location: Location,
    private ngxSpinner: NgxSpinnerService
  ) {
    // this.permissionsService.permissions$.subscribe((permissions) => {
    //   console.log(permissions);
    //   let role = [];
    //   for (const property in permissions) {
    //     role.push(property);
    //   }
    //   if (role.length > 0) {
    //     if (role.includes('DEFAULT')) {
    //       this.router.navigate(['/unauthorized']);
    //       return true;
    //     }
    //   }
    // })
    this.broadcastService.subscribe("msal:loginSuccess", payload => {
      this.btnTxt = "Request New Environment";
      this.title = "Environment";
    });
  }

  ngOnInit() {
    var connectors: any = [];
    this.requestDetail = this.commonService.getRequestData();
    if (this.requestDetail == undefined || this.requestDetail == null || this.requestDetail.Connectors == undefined || this.requestDetail.Connectors.length == 0) {
      this.router.navigate(['/services']);
    } else {
      this.requestDetail.Connectors.forEach(function (con) {
        let connect: any = {
          "value": ''
        };
        connect.value = con;
        connectors.push(connect);
      });
      var connectorJson = {
        "connectors": connectors,
        "type": this.requestDetail.EnvironmentType,
        "cds": this.requestDetail.CDS == 'Yes' ? "true" : "false",
        "region": this.requestDetail.Region
      }

      this.getEnvironments(connectorJson);
    }
  }
  /*--------  END OF FUNCTION -------- */

  /*--------  FUNCTION TO GET ENVIRONMENTS BASED ON CONNECTORS SEND FROM CREATE REQUEST PAGE -------- */
  async getEnvironments(connectors) {
    this.norecord = false;
    this.apibundleService.showSpinner('reqEnvironment'); 
    this.spinner = true; 
    this.apibundleService.getEnvironments(connectors).subscribe(
      res => {
        this.envList = res;
        if(this.envList.length == 0) {
          this.norecord = true;
        }
        setTimeout(() => {
          this.formatData();
          this.apibundleService.hideSpinner('reqEnvironment');  
          this.spinner = false;
        }, 2000);
      },
      err => {
        this.err = err;
        console.log(err.text);
        this.apibundleService.hideSpinner('reqCreation');  
        this.spinner = false;
      });
  }
  /*--------  END OF FUNCTION -------- */

  /*--------  FUNTION WILL THE CALL THE APPROPRIATE FUNCTION TO CREATE REQUEST FOR NEW OR EXISTING ENVIRONMENT -------- */
  submitRequest(environment) {
    if (environment == 'New') {
      this.submitRequestForNewEnvironment(environment);
    } else {
      this.submitRequestForExistingEnvironment(environment);
    }
  }
  /*--------  END OF FUNCTION -------- */

  /*--------  FUNTION TO CREATE REQUEST FOR NEW ENVIRONMENT -------- */
  async submitRequestForNewEnvironment(environmentId: string) {
    this.ngxSpinner.show();
    this.requestDetail.Environmentid = '';
    this.requestDetail.ExistingEnvironment = "No";
    this.apibundleService.createRequestForNewEnvironment(this.requestDetail).subscribe(
      res => {
        this.ngxSpinner.hide();
        this.commonService.openSnackBar("Request to create new environment has been created successfully", "OK");
        this.router.navigate(['/services']);
      },
      err => {
        this.err = err;
        console.log(err);
        this.ngxSpinner.hide();
      });
  }
  /*--------  END OF FUNCTION -------- */

  /*--------  FUNTION TO CREATE REQUEST FOR EXISTING ENVIRONMENT -------- */
  async submitRequestForExistingEnvironment(environment) {
    this.ngxSpinner.show();
    this.requestDetail.Environmentid = environment.id;
    this.requestDetail.ExistingEnvironment = "Yes";
    this.requestDetail.EnvironmentName = environment.name;
    this.apibundleService.createRequestForExistingEnvironment(this.requestDetail).subscribe(
      res => {
        this.ngxSpinner.hide();
        this.commonService.openSnackBar("Request to access existing environment has been created successfully", "OK");
        this.router.navigate(['/services']);
       },
      err => {
        this.err = err;
        console.log(err);
        this.ngxSpinner.hide();
      });

  }
  /*--------  END OF FUNCTION -------- */

  /*--------  FUNTION TO FORMAT DATA FOR TABLE DISPLAY, ADD PAGINATION AND SORTING -------- */
  formatData() {
    this.dataSource = new MatTableDataSource<any>(this.envList);
    this.dataSource.paginator = this.paginators;
    this.dataSource.sort = this.sort;
  }
  /*--------  END OF FUNCTION -------- */
}
