import { Injectable } from '@angular/core';
import { RequestDetailRequest } from '../interfaces/requestDetailRequest.model';
import { RequestDetail } from '../interfaces/requestDetail.model';
import { DatePipe } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  requestDetail = new RequestDetailRequest();

  constructor(private datePipe: DatePipe, private _snackBar: MatSnackBar) {
  }

  /* -------- COMMON FUNCTION TO CREATE REQUEST WITH EXISTING ENVIRONMMENT.. RETURN REQUEST LIST-------- */
  public changeDateFormatAndSetPendingStatus(requestList) {
    let context = this;
    requestList.forEach(function (req) {
      req.timestamp = context.datePipe.transform(req.timestamp, 'mediumDate');
      if (req.requestStatus == 'New') {
        req.requestStatus = 'Pending';
      }
    });
    return requestList;
  }
  /* -------- END OF COMMON FUNCTION-------- */

  /* -------- COMMON FUNCTION TO REQUEST BY DATE CEATED IN DESCENDING ORDER. RETURN REQUEST LIST-------- */
  public sortDescByDate(requestList) {
    var requestList = requestList.sort((a, b) => {
      return <any>new Date(b.timestamp) - <any>new Date(a.timestamp);
    });
    return requestList;
  }
  /* -------- END OF COMMON FUNCTION-------- */

  /* -------- GETTER SETTER USED TO GET REQUEST AND SET REQUEST -------- */
  setRequestData(reqData) {
    this.requestDetail = reqData;
  }
  getRequestData() {
    return this.requestDetail;
  }
  /* -------- END OF GETTER SETTER-------- */

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
}
