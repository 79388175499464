import { Component, OnInit, OnDestroy } from '@angular/core';
import { ApibundleService } from '../../services/apibundle.service';
import { MessageService } from '../../services/message.service';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';

// export interface Vegetable {
//   name: string;
//   id:number;
// }

@Component({
  selector: 'app-articles',
  templateUrl: './articles.component.html',
  styleUrls: ['./articles.component.css'],
})
export class ArticlesComponent implements OnInit, OnDestroy {
  //slides:any = []
  posts: Array<any>;
  searchText = '';
  component = '';
  error: boolean = false;
  errorMsg: string = '';
  spinnerName = "articles";
  spinner = false;
  noRecords: boolean = false;
  max = 4;
  btnloadMoreDisable = false;

  constructor(
    private apibundleService: ApibundleService,
    private messageService: MessageService,
  ) {

    this.messageService.getMessage().subscribe(message => {
      if (message == this.messageService.searchText) {
        this.searchText = message[0].searchText;
        this.component = message[0].component;
      }
    });

  }

  // vegetables: Vegetable[] = [
  //   {name: 'Tag1', id:3},
  //   {name: 'Show All', id:0},   
  // ];

  // drop(event: CdkDragDrop<Vegetable[]>) {
  //   moveItemInArray(this.vegetables, event.previousIndex, event.currentIndex);
  // }

  loadMore() {
    if(this.posts.length < this.max){
      this.btnloadMoreDisable = true;
    } else {
      this.btnloadMoreDisable = false;
    }
    this.max = this.max + 4;
  }  

  loadArticlesByCategory(categoryId) {
    this.apibundleService.showSpinner('articles');
    this.spinner = true;
    this.apibundleService.getArticles(categoryId).subscribe(
      response => {
        this.posts = response;
        if (this.posts.length) {
          this.apibundleService.hideSpinner('articles');
          this.spinner = false;
          this.error = false;
          this.noRecords = false;
        } else {
          this.spinner = false;
          this.noRecords = true;
        }
      },
      error => {
        console.log(error);
        this.error = true;
        this.errorMsg = "Something went wrong , Please try after some time...";
        this.apibundleService.hideSpinner('articles');
        this.spinner = false;
      }
    );
  }
  
  ngOnInit(): void {
    this.loadArticlesByCategory(1);    
  }
 
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.apibundleService.hideSpinner('articles');
    this.spinner = false;
  }

}
